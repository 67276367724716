import React from "react";
import Styles from "./VideoModal.module.css";

const VideoModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="videoModal"
        tabIndex="-1"
        aria-labelledby="videoModalLabel"
        aria-hidden="true"
      >
        <div className="row">
          <div className="col-12 text-end">
            <button
              type="button"
              className="btn text-white text-end p-5"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className={`fa-solid fa-xmark ${Styles.closeSize}`}></i>
            </button>
          </div>
        </div>
        <div
          className={`modal-dialog modal-dialog-centered modal-xl ${Styles.modal_adjust}`}
        >
          <div className="modal-content bg-transparent border-0">
            <div className="ratio ratio-16x9">
              <iframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/742647006?h=7f1cb15a1b"
                width="570"
                height="360"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoModal;
