import React, { useState, useReducer, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
// Styles
import Styles from "./Checkout.module.css";
// Api Services
import CheckoutService from "../../services/CheckoutService";
import ToastHelper from "../../helpers/ToastHelper";
// Components
import CheckoutCart from "./components/CheckoutCart";
import BatchNotFound from "./components/BatchNotFound";
import PaymentMethod from "./components/PaymentMethod";
import UserDetails from "./components/UserDetails";
// Other Imports
import useDocumentTitle from "../../UserDocumentTitle";
import AuthService from "../../services/AuthService";

// Forms validation
const firstNameReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { val: action.val, isValid: action.val.trim().length >= 3 };
  }
  if (action.type === "LAST_NAME") {
    return { val: action.val, isValid: action.val.trim().length >= 1 };
  }
  if (action.type === "PHONE_NO") {
    return { val: action.val, isValid: action.val.length === 10 };
  }
  if (action.type === "COLLEGE") {
    return { val: action.val, isValid: action.val.trim().length > 3 };
  }
  return { val: "", isValid: false };
};

const Checkout = () => {
  // Setting up tab title
  useDocumentTitle("Payment | Algozenith");

  const [searchParams, setSearchParams] = useSearchParams();
  // cookies
  const [cookies, setCookie] = useCookies(["user"]);
  // States
  const [activeCard, setActiveCard] = useState(true);
  const [activeCardEmi, setActiveCardEmi] = useState(false);
  const [amount, setAmount] = useState(11999);
  const [partial, setPartial] = useState(0);
  const [coupon, setCoupon] = useState(searchParams.get("couponcode"));
  const [invitekey, setInvitekey] = useState(searchParams.get("invitekey"));
  const [orderId, setOrderId] = useState("");
  const [couponRes, setCouponRes] = useState({ status: false, data: 0 });
  const [loading, setLoading] = useState({
    applyCoupon: false,
    paymentProceed: false,
  });
  // Query Parameter
  const coursecode = searchParams.get("coursecode");

  const handleCoupon = async () => {
    if (coupon) {
      setLoading({ ...loading, applyCoupon: true });
      CheckoutService.ApplyCoupon(coupon, amount)
        .then((res) => {
          setCouponRes({
            status: res.data.status,
            data: res.data.data,
          });
          document.getElementById("exampleModal2").click();
          setLoading({ ...loading, applyCoupon: false });
        })
        .catch((error) => {
          setLoading({ ...loading, applyCoupon: false });
          ToastHelper.ShowError(error.response.data.details);
        });
    }
  };

  useEffect(() => {
    if (!AuthService.LoggedIn()) {
      window.open(
        process.env.REACT_APP_LEARNING_URL +
          `/login?src=checkout&coursecode=${coursecode}&invitekey=${invitekey}&couponcode=${coupon}`,
        "_self"
      );
      return;
    }
    console.log("Inside UseEffect");
    handleCoupon();
  }, []);

  //Forms Reducer
  const [firstNameState, dispatchFirstName] = useReducer(firstNameReducer, {
    val: "",
    isValid: null,
  });
  const [lastNameState, dispatchLastName] = useReducer(firstNameReducer, {
    val: "",
    isValid: null,
  });
  const [phoneNoState, dispatchPhoneNo] = useReducer(firstNameReducer, {
    val: "",
    isValid: null,
  });
  const [collegeState, dispatchcollege] = useReducer(firstNameReducer, {
    val: "",
    isValid: null,
  });

  //First Name
  const firstNameChangeHandler = (event) => {
    dispatchFirstName({ type: "USER_INPUT", val: event.target.value });
  };

  const lastNameChangeHandler = (event) => {
    dispatchLastName({ type: "LAST_NAME", val: event.target.value });
  };

  const phoneNoChangeHandler = (event) => {
    dispatchPhoneNo({ type: "PHONE_NO", val: event.target.value });
  };

  const collegeChangeHandler = (event) => {
    dispatchcollege({ type: "COLLEGE", val: event.target.value });
  };

  const updateSuccessOrder = async (order, response) => {
    let body = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
    };
    CheckoutService.UpdateSuccessOrder(order, body)
      .then((res) => {
        document.getElementById("exampleModalSu").click();
        setTimeout(() => {
          window.open(
            `${process.env.REACT_APP_LEARNING_URL}/dashboard"`,
            "_self"
          );
        }, 3000);
      })
      .catch((error) => {
        ToastHelper.ShowError(error.response.data.details);
      });
  };

  const openCheckoutForm = (order) => {
    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: order.amount * 100,
      currency: "INR",
      name: "AlgoZenith Technologies Private Limited",
      description: "AZ-201 Course Purchase",
      image: "http://algozenith.com/images/logo.png",
      order_id: order.order_id,
      handler: function (response) {
        updateSuccessOrder(order, response);
      },
      prefill: {
        // "name": firstNameState.val,
        // "email": AuthService.GetEmail(),
        // "contact": phoneNoState.val
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };

  const handlePayment = (e) => {
    setActiveCard(true);
    setActiveCardEmi(false);
    setAmount(11999);
    setPartial(0);
  };

  let batchId = 0;
  if (coursecode === "az201triumph") {
    batchId = 54;
  } else if (coursecode === "az201summit") {
    batchId = 55;
  } else {
    batchId = null;
  }
  let order = {
    batch_id: batchId,
    referral_code: "",
    first_name: firstNameState.val,
    last_name: lastNameState.val,
    phone: phoneNoState.val,
    college: collegeState.val,
    order_id: "",
    coupon_code: coupon,
    referral_code: invitekey,
    partial: partial,
  };

  const handleRayzorpay = () => {
    setLoading({ ...loading, paymentProceed: true });
    if (
      firstNameState.isValid &&
      lastNameState.isValid &&
      collegeState.isValid &&
      phoneNoState.isValid
    ) {
      CheckoutService.CreateOrder(order)
        .then((res) => {
          setOrderId(res.data.data.order_id);
          openCheckoutForm(res.data.data);
        })
        .catch((error) => {
          setLoading({ ...loading, paymentProceed: false });
          ToastHelper.ShowError(error.response.data.details);
        });
    } else {
      ToastHelper.ShowError("All Fields are Mandatory");
    }
  };

  return (
    <>
      {batchId ? (
        <section className={Styles.checkoutBg}>
          <div className="container-lg container-fluid py-5">
            <ToastContainer></ToastContainer>
            <div className="row">
              <div className="col-md-8">
                <PaymentMethod
                  activeCard={activeCard}
                  handlePayment={handlePayment}
                  activeCardEmi={activeCardEmi}
                />
                <UserDetails
                  firstNameState={firstNameState}
                  firstNameChangeHandler={firstNameChangeHandler}
                  lastNameState={lastNameState}
                  lastNameChangeHandler={lastNameChangeHandler}
                  phoneNoState={phoneNoState}
                  phoneNoChangeHandler={phoneNoChangeHandler}
                  collegeState={collegeState}
                  collegeChangeHandler={collegeChangeHandler}
                  coupon={coupon}
                  setCoupon={setCoupon}
                  inviteKey={invitekey}
                  setInviteKey={setInvitekey}
                  handleCoupon={handleCoupon}
                  loading={loading}
                  handleRayzorpay={handleRayzorpay}
                />
              </div>
              <div className="col-md-4">
                <CheckoutCart amount={amount} discount={couponRes.data} />
              </div>
            </div>
          </div>

          <button
            type="button"
            className={`btn ${Styles.btn_primary_mod}`}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            id="exampleModal2"
          >
            Launch demo modal
          </button>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div
                  className={`modal-body text-center ${Styles.modal_content_bg}`}
                >
                  <div className="row">
                    <div className="col-12 text-end">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <img
                    src="/img/Payment/confetti-42-trans.gif"
                    className="img-fluid"
                    width="400"
                    style={{ marginTop: "-50px" }}
                    alt=""
                  />
                  <p className={`text-success ${Styles.coupon_code_ts}`}>
                    <span className={`text-danger`}>"{coupon}" </span>{" "}
                    Successfully Applied
                  </p>
                  <hr className="hr" />
                  <p className={`${Styles.price_discount_ts}`}>
                    ₹{couponRes.data}
                  </p>
                  <p className={`${Styles.coupon_ts}`}>
                    saved with this coupon
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Modal for successful payments  */}

          <button
            type="button"
            className={`btn ${Styles.btn_primary_mod}`}
            data-bs-toggle="modal"
            data-bs-target="#exampleModalSu"
          >
            Launch demo modal
          </button>

          <div
            class="modal fade"
            id="exampleModalSu"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div
                  className={`modal-body text-center ${Styles.modal_content_bg}`}
                >
                  <div className="row">
                    <div className="col-12 text-end">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <img
                    src="/img/Payment/process-complete.gif"
                    className="img-fluid"
                    width="400"
                    style={{ marginTop: "-50px" }}
                    alt=""
                  />
                  <hr />
                  <p className={`text-success ${Styles.coupon_code_ts}`}>
                    The enrolment process is complete. Please login and start
                    your awesome journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <BatchNotFound />
      )}
    </>
  );
};

export default Checkout;
