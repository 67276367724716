import React from "react";
import Styles from "../Checkout.module.css";

const PaymentMethod = ({ activeCard, handlePayment, activeCardEmi }) => {
  return (
    <>
      <div className="card">
        <div className="card-body">
          <p className={Styles.payment_ts}>Payment Method</p>
          <hr className={Styles.hr} />
          <div className="row my-4">
            <div className="col-6">
              <div
                className={`card ${
                  activeCard ? Styles.paymentCardActive : Styles.paymentCard
                } `}
                onClick={() => handlePayment(0)}
              >
                <div className="card-body text-center">
                  <div className="d-flex justify-content-between">
                    <p className={Styles.card_inner_ts}>One-Time Price</p>
                    <label>
                      <input
                        type="radio"
                        className={`${Styles.option_input} ${Styles.radio} radio"`}
                        name="example"
                        checked={activeCard}
                      />
                    </label>
                  </div>
                  <hr className={Styles.hr} />
                  <p className={Styles.strike}>₹ 20,000 /-</p>
                  <p className={Styles.amount}>₹ 11,999 /-</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
