import axios from "axios";
import ToastHelper from "helpers/ToastHelper";
import AuthService from "./AuthService";
import { Navigate } from "react-router-dom";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = AuthService.GetToken();
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    // console.log("Err: " + err.response);
    // const navigate = useNavigate();
    // const [searchParams, setSearchParams] = useSearchParams();
    // console.log("Err: " + err.response);
    if (
      !originalConfig.url.includes("/users/login") &&
      err.response &&
      err.response.status === 401
    ) {
      try {
        const res = await instance.post("/users/refresh", {
          azref: AuthService.GetRefreshToken(),
        });

        AuthService.SetAccessToken(res.data.data.token);
        AuthService.SetRefreshToken(res.data.data.azref);

        return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    } else if (err.response.status === 403) {
      ToastHelper.ShowError("Invalid Session. Logging out!");
      AuthService.ClearAll();
      //   navigate({
      //     pathname: Urls.Learning.LoginUrl(),
      //     search: searchParams,
      //   });

      //   this.authService.clearUserData();

      //   this.router.navigate(["/login"], {
      //     queryParams: { returnUrl: window.location.pathname },
      //   });
    }
    // Bad Request
    else if (err.response.status === 400) {
      //   this.toastr.error(error.error.details);
    }
    // Internal Server Error
    else if (err.response.status === 500) {
      <Navigate to="/500" replace={true} />
      //   this.router.navigate(["/500"]);
    }
    // Too Many requests Throttling
    else if (err.response.status === 429) {
      //   this.toastr.error("Try after some time.");
    }
    // Default Error
    else {
      <Navigate to="/404" replace={true} />
      //   this.router.navigate(["/404"]);
    }
    return Promise.reject(err);
  }
);

export default instance;
