export const faq = [
  {
    id: 1,
    head: "About",
    qa: [
      {
        question: "Which Course Should I take?",
        answer:
          "We ideally recommend everyone to start from the AZ-201/AZ-202 courses. These courses are designed to start building your fundamentals and systematically increase your problem-solving aptitude. AZ-203 is designed as a Fast-Paced version of AZ-201. Currently we are only running the batches of AZ-201.",
      },
      {
        question: "Who will take the sessions?",
        answer:
          "We have a team of Top Mentors who are expert educators and top achievers themselves. They curate the contents, design the problems and resources, and deliver the same in live sessions. Rest assured that you will be guided by the best we have. These live sessions will be recorded and will be made available on the AlgoZenith Platform.",
      },
      {
        question: "What will be the deliverables?",
        answer:
          "The Deliverables vary from course to course, ranging from Live Sessions, Pre-Recorded Videos, Practice Problems, Mock Interviews, etc. Please check the individual course pages or brochures to get more details.",
      },
      {
        question: "Will the course content be prerecorded or live?",
        answer:
          "Some parts of the course are pre-recorded. But to make difficult concepts easier for you, we will take 1-2 live sessions per week, so that you can learn tough topics in live sessions directly from experts.",
      },
    ],
  },
  {
    id: 2,
    head: "Pre-Requistes",
    qa: [
      {
        question:
          "Are there any requirements to join the AZ-201 and AZ-202 course?",
        answer:
          "No, there is no requirement for these courses. The pre-requisite for the course is knowledge of at least one language (C++ preferably), and determination.",
      },
      {
        question: "Are there any requirements to join the AZ-203 course?",
        answer:
          "No, there is no requirement for this course. However, since this is a fast-paced course, you should be fluent with coding in at least one language (we would not be going into the nitty-gritty of codes). Also having experience with an Algo&DS course should be helpful. This course is designed to keep you up to the mark with current trends of Coding rounds and Interviews.",
      },
      {
        question: "Are there any requirements to join the AZ-301 course?",
        answer:
          "You should have: (completed AZ-202/AZ-201) or (have a rating >1800 on CF and pass screening test organized by us).",
      },
      {
        question:
          "Can I join AZ301 if I don't have an 1800 code forces rating?",
        answer:
          "If you have done any of the courses out of AZ-201 or AZ-202, you are eligible, or else, No.",
      },
    ],
  },
  {
    id: 3,
    head: "Payment Policy",
    qa: [
      {
        question: "What is the cost of the course?",
        answer:
          "Check out the individual course pages for the details regarding the course price and payment plans.",
      },
      {
        question: "Can I leave the course in between? Will I get a Refund?",
        answer:
          "We provide refunds if you leave within the first week of any course (its first module). However, we don’t provide refunds in between the course. Also if you leave the course in between, we won’t be entertaining any resume request, you will have to join the next batch from start.",
      },
    ],
  },
  {
    id: 4,
    head: "Live Classes",
    qa: [
      {
        question:
          "What will be the timings of the sessions (what if it clashes with some other task)?",
        answer:
          "We would let you know the plan for the next week beforehand. The sessions will mostly happen in 2 slots on Saturdays and Sundays ( or sometimes at night on weekdays ). The choice of the slot will remain with the mentors. Also, the live session’s recording will be made available on the website, so that you can revise if needed.",
      },
      {
        question: "Will the Schedule of Live sessions be provided earlier?",
        answer:
          "We will keep you updated with the schedule of at least a week ahead.",
      },
      {
        question: "How will the Live sessions be conducted?",
        answer:
          "It will be mostly conducted on online video conferencing platforms.",
      },
    ],
  },
  {
    id: 5,
    head: "Live Contests",
    qa: [
      {
        question: "What platform will be used for the AZ contests?",
        answer: "We have our Platform to host all problems/contests.",
      },
      {
        question: "How frequently will the contests be held?",
        answer:
          "Contests will be held once a week. The format of the contest would be long or short depending on whatever the mentors deem right.",
      },
    ],
  },
  {
    id: 6,
    head: "Course Access",
    qa: [
      {
        question: "How long will the AZ201 course be accessible?",
        answer:
          "The whole training is divided into 2 parts ie. CP Core ( Module 0-3 ) and the Interview  Bootcamp ( Module 4 ). The last date for <b> Access for the CP Core Training will be 270 days from the date of purchase. </b><br> The Interview Bootcamp will be held every year at 2 specific times: <br>\
				1. Mid-End May to Mid-End June -> For Campus Internships and Placements in July-Aug. <br>\
				2. Mid-End Sept to Mid-End Oct -> For Campus Internships and Placements in Nov-Dec. <br>\
				The last date for <b> Access for the Bootcamp will be 90 days </b>from the start of your selected dates, detached from the rest of training (so that you are even free to take it the next year, or anytime you feel its the right time). <br>",
      },
      {
        question:
          "Is there any way to extend the course access for AZ201 & Bootcamp?",
        answer:
          "<b>Extension Plan For Core CP Course </b> : The cost of extension following the 270 day period => For 1st 30 days => INR 100, Next 30 days => INR 500, Next 30 days => INR 1000, Next 30 days => INR 1500, and so on.  <br>\
				<b>Extension Plan for Bootcamp</b> : The cost of extension following the 90 day period => For 1st 30 days => INR 100, Next 30 days => INR 300, Next 30 days => INR 600, Next 30 days => INR 900, and so on.  <br>",
      },
    ],
  },
  {
    id: 7,
    head: "Referral Policy",
    qa: [
      {
        question: "What is the referral policy of AlgoZenith?",
        answer:
          `If you have heard about AlgoZenith from someone who has taken AZ Course, you can take a referral from them, and both you and the person from whom you take a referral gets a INR 500 Cashback. After joining the course, even you can refer any number of person and get INR 500 Cashback per person who enters the training and is directed by you. You can refer even if either of you choose partial payment but the cashback will be processed only after both sides have made the full purchase of any of AZ-201/AZ-202/AZ-203/AZ-301 Courses. The Cashback will be processed once the particular batch commences. ${<br/>}
				<b>Note: Only one of referral or coupon code can be applied. If you have applied any coupon then the referral cashback will be invalid.</b>`,
      },
    ],
  },
  {
    id: 8,
    head: "Miscellaneous",
    qa: [
      {
        question: "How will the doubts be resolved in the course?",
        answer:
          "There is a dedicated doubt forum available on the platform. You can ask your doubts there at any time of the day.",
      },
      {
        question: "Will there be a Mock interview in my course?",
        answer:
          "Mock interviews will be taken of those who join the AZ-201/AZ-203 course, As these courses are specifically focused on the aspect of interview preparation.",
      },
      {
        question:
          "I am having trouble accessing some part of the website, what should I do?",
        answer:
          "Please contact us at contact@algozenith.com, We would love to help you.",
      },
      {
        question: "Can I take 2 courses at a time?",
        answer:
          "No. It’s strongly recommended to not do that. Each course is designed by considering the necessity and goals of people.",
      },
    ],
  },
];
