import React, { useContext } from "react";
import { FaqContext } from "../../../context/FaqContext";

import Styles from "../Faqs.module.css";

const FaqHead = () => {
  const { faqData, setFaqDataHandle } = useContext(FaqContext);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-start">
            <img
              src="/img/faq/question.svg"
              className="img-fluid me-2 pb-4"
              width={25}
              alt=""
            />
            <p className={Styles.faq_head}>Faqs</p>
          </div>

          {faqData.map((data) => (
            <div key={data.id} onClick={() => setFaqDataHandle(data.id)}>
              <hr className={Styles.hr} />
              <div
                className={`d-flex justify-content-between py-2 ${Styles.side_head_ts}`}
              >
                <p>{data.head}</p>
                <i className={`fa-solid fa-angle-right text-secondary`}></i>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FaqHead;
