import React, { useEffect } from "react";
import Styles from "./PremiumPage.module.css";
import { useState } from "react";

const TimerCountdown = () => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  let currentDate = new Date();
  if (currentDate.getHours() >= 5) {
    currentDate.setDate(currentDate.getDate() + 1);
  }
  currentDate.setHours(5, 0, 0, 0);

  const getTime = () => {
    const time = Date.parse(currentDate) - Date.now();
    setHours(Math.floor(time / (1000 * 60 * 60)));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(currentDate), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <p className={Styles.timmer_head}>
        Get 20 Mock Tests Free if you Join as an early bird before{" "}
        {currentDate.toLocaleDateString("en-US", options)}
      </p>
      <div className="d-flex justify-content-center my-2">
        <div className="me-2">
          <p className={Styles.timmer_ts}>{("0" + hours).slice(-2)} :</p>
          <p className={Styles.timmer_ts_hrs}>Hours </p>
        </div>
        <div>
          <p className={Styles.timmer_ts}>{("0" + minutes).slice(-2)} :</p>
          <p className={Styles.timmer_ts_hrs}>Mins</p>
        </div>
        <div className="ms-2">
          <p className={Styles.timmer_ts}>{("0" + seconds).slice(-2)} </p>
          <p className={Styles.timmer_ts_hrs}>Sec</p>
        </div>
      </div>
    </>
  );
};

export default TimerCountdown;
