import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import Styles from "./HomePage.module.css";
import QueryForm from "../../components/QueryForm/QueryForm";
import useDocumentTitle from "../../UserDocumentTitle";
import VideoModal from "../../components/Video/VideoModal";
import { Urls } from "../../constants/Urls";

const HomePage = () => {
  useDocumentTitle("Home | Algozenith");

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <section>
        <div className="container">
          <div className="row mt-5 mt-lg-0">
            <div className="col-12 col-lg-6 ps-md-5 text-center text-lg-start my-auto">
              <p className={Styles.hero_head}>
                <span className={Styles.Awesome_hero_ts}>Algozenith</span>
                , <br /> an{" "}
                <span className={Styles.Algo_hero_ts}>Algorithm</span> to become{" "}
                <br />
                <span className={Styles.Awesome_hero_ts}>Awesome</span> at{" "}
                <span className={Styles.Awesome_hero_ts}>DSA</span> and
                <span className={Styles.Awesome_hero_ts}> CP</span>
              </p>
              <img
                src="/img/Home-Page/hero-head.svg"
                className={`img-fluid py-3 ${Styles.hide_lg}`}
                width="350"
                alt=""
              />
              <p className={`${Styles.hero_sub_text} py-4`}>
                A CAREFULLY crafted, COMPREHENSIVE, LIVE TRAINING based
                ALGORITHM!
              </p>
              <div className="d-flex justify-content-center justify-content-lg-start pt-1 mb-5 mb-lg-0">
                <Link
                  to="/premium/#join-today"
                  className={`${Styles.btn_join_now} btn px-md-4`}
                >
                  Join Today
                </Link>
                <button
                  className={`${Styles.Watch_btn} border-0 bg-transparent ms-5`}
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                >
                  <img
                    src="/img/Home-Page/video-paly.svg"
                    className="img-fluid me-2"
                    width="40"
                    alt=""
                  />
                  <span className={Styles.highlight}>Watch Video</span>
                </button>
              </div>
            </div>
            <div
              className={`col-12 col-lg-6 text-center text-lg-end ${Styles.hide_sm} ${Styles.hero_right_bg}`}
            >
              <div>
                <img
                  src="/img/Home-Page/hero-head.svg"
                  className="img-fluid py-5"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Styles.statistics_bg}>
        <div className="container-fluid container-lg py-4">
          <div className="row">
            <div
              className={`col-12 col-md-3 my-auto text-center text-md-start ${Styles.border_right}`}
            >
              <p className={Styles.stats_ts}>
                We've proven <br /> Our numbers <br /> speaks for itself.
              </p>
            </div>
            <div className="col-4 col-md-3 text-center my-auto">
              <p className={Styles.stats_ts}>2000+</p>
              <p className={Styles.sub_text}>Happy Students</p>
            </div>
            <div className="col-4 col-md-3 text-center my-auto">
              <p className={Styles.stats_ts}>5000+</p>
              <p className={Styles.sub_text}>Doubts resolved</p>
            </div>
            <div className="col-4 col-md-2 text-center my-auto">
              <p className={Styles.stats_ts}>5.0/5</p>
              <p className={`${Styles.sub_text}`}>Rating on Google</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              <p className={Styles.solve_prob_head_ts}>
                We <span className={Styles.solve_prob_span}>Solve</span> all
                your{" "}
                <span
                  className={`${Styles.solve_prob_span} ${Styles.highlight}`}
                >
                  Problems
                </span>
              </p>
            </div>
            <div className="col-12 col-md-6 col-xl-3 px-4 px-md-3">
              <div className={`card ${Styles.problems_card_mod}`}>
                <div className="card-body text-center">
                  <div className={`card ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      <img
                        src="/img/Home-Page/top notch dsa.webp"
                        className="img-fluid"
                        width="200"
                        alt=""
                      />
                    </div>
                  </div>
                  <p className={`${Styles.card_head} mt-4`}>
                    Top Notch DSA & CP Prep
                  </p>
                  <p className={`${Styles.card_text} card-text mt-3`}>
                    Upto date with recent trends of coding tests and interviews.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-0 px-4 px-md-3">
              <div className={`card ${Styles.problems_card_mod}`}>
                <div className="card-body text-center">
                  <div className={`card ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      <img
                        src="/img/Home-Page/resume.webp"
                        className="img-fluid mt-2"
                        width="180"
                        alt=""
                      />
                    </div>
                  </div>
                  <p className={`${Styles.card_head} mt-4`}>
                    Project for Awesome CV
                  </p>
                  <p className={`${Styles.card_text} card-text mt-3`}>
                    So that you don’t have to seperately worry about the resume.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-5 mt-xl-0 px-4 px-md-3">
              <div className={`card ${Styles.problems_card_mod}`}>
                <div className="card-body text-center">
                  <div className={`card ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      <img
                        src="/img/Home-Page/mock-interview.webp"
                        className="img-fluid"
                        width="200"
                        alt=""
                      />
                    </div>
                  </div>
                  <p className={`${Styles.card_head} mt-4`}>
                    Mock tests and Interview
                  </p>
                  <p className={`${Styles.card_text} card-text mt-3`}>
                    Cause practice makes you perfect with skills and
                    temperament.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-5 mt-xl-0 px-4 px-md-3">
              <div className={`card ${Styles.problems_card_mod}`}>
                <div className="card-body text-center">
                  <div className={`card ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      <img
                        src="/img/Home-Page/Refferrals.webp"
                        className="img-fluid mt-3"
                        width="180"
                        alt=""
                      />
                    </div>
                  </div>
                  <p className={`${Styles.card_head} mt-4`}>
                    Rich Alum base for Referrals
                  </p>
                  <p className={`${Styles.card_text} card-text mt-3`}>
                    Reach out to students who know your worth via the training.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 text-center pt-4 mt-5">
              <div className={`card ${Styles.card_quote_mod}`}>
                <div className="card-body px-5 py-4">
                  <p className={Styles.quote_ts}>
                    " Structured Training + Your Dedication ={" "}
                    <span className={Styles.inner_quote_ts}>
                      Sure shot way of Success
                    </span>{" "}
                    "
                  </p>
                  <p className={Styles.quote_sub_text}>
                    No more hunting Problems & Quality videos in the ocean of
                    internet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="mt-5">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <p className="sliding-text">How are we different - <span className="sliding-text-mod highlight">Yes, we
                            actually are.</span></p>
                </div>
            </div>
        </div>
    </section>  */}

      <section className={Styles.course_bg}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 my-auto text-center text-lg-start">
              <p className={`${Styles.course_head} mt-5 mt-lg-0`}>
                Fed up even after putting so much of efforts ??
              </p>
              <img
                src="/img/Home-Page/bored-courses.webp"
                width="200"
                className={`img-fluid ${Styles.hide_lg} py-2`}
                alt=""
              />
              <p className={`${Styles.course_subhead} pb-3`}>
                AlgoZenith Premium trains you like a champion in a out of
                comfort challenging regime, resulting in 10x returns for all
                your efforts !!
              </p>
              <div className="d-flex justify-content-sm-center justify-content-lg-start pt-1 mb-5 mb-lg-0">
                <Link
                  to="/premium/#join-today"
                  className={`${Styles.btn_join_now} btn px-md-4`}
                >
                  Join Premium Today
                </Link>
                <button
                  className={`${Styles.Watch_btn} border-0 bg-transparent ms-5`}
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                >
                  <img
                    src="/img/Home-Page/video-paly.svg"
                    className="img-fluid me-2"
                    width="40"
                    alt=""
                  />
                  <span className={`${Styles.highlight}`}>Watch Video</span>
                </button>
              </div>
            </div>
            <div className={`col-md-4 text-end ${Styles.hide_sm}`}>
              <img
                src="/img/Home-Page/bored-courses.webp"
                width="300"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5">
        <div className="container">
          <div className="row mb-md-5">
            <div className="col-12 text-center">
              <p className={Styles.sliding_text}>
                We have got you covered -
                <span
                  className={`${Styles.sliding_text_mod} ${Styles.highlight} `}
                >
                  No matter where you are now !
                </span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 px-lg-5 mt-5 mt-md-0">
              <div className={`card ${Styles.card_mod_features}`}>
                <div className="card-body py-4">
                  <div className="row px-2">
                    <div className="col-3 my-auto">
                      <img
                        src="/img/Home-Page/UP SKILLING.webp"
                        width="80"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <p className={Styles.card_head_features}>UPSKILLING</p>
                      <p className={Styles.card_subhead_features}>
                        1st - 2nd Years
                      </p>
                    </div>
                    <div className="col-12 py-3">
                      <p className={Styles.card_text_features}>
                        Learn with real work experience and get guaranteed
                        placement as a Full-Stack or Backend Developer at
                        product-based companies.
                      </p>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/upskilling-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Get the HeadStart
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/upskilling-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Top Notch Fundamentals
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/upskilling-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Nurture into path for success
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/upskilling-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Learn from Elites in DSA and CP
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 px-lg-5 mt-5 mt-md-0">
              <div className={`card ${Styles.card_mod_features_intern}`}>
                <div className="card-body py-4">
                  <div className="row px-2">
                    <div className="col-3 my-auto">
                      <img
                        src="/img/Home-Page/INTERNSHIP.webp"
                        width="80"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <p className={Styles.card_head_features}>INTERNSHIP</p>
                      <p className={Styles.card_subhead_features}>
                        3rd - 4th Years
                      </p>
                    </div>
                    <div className="col-12 py-3">
                      <p className={Styles.card_text_features}>
                        Learn with real work experience and get guaranteed
                        placement as a Full-Stack or Backend Developer at
                        product-based companies.
                      </p>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/internship-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Separate Regime for Coding test and Interviews
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/internship-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Based on Recent trends of tests
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/internship-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Building an Ace Resume
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/internship-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Project Building
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 px-lg-5 mt-5">
              <div className={`card ${Styles.card_mod_features_placement}`}>
                <div className="card-body py-4">
                  <div className="row px-2">
                    <div className="col-3 my-auto">
                      <img
                        src="/img/Home-Page/PLACEMENT.webp"
                        width="80"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <p className={Styles.card_head_features}>PLACEMENT</p>
                      <p className={Styles.card_subhead_features}>Final Year</p>
                    </div>
                    <div className="col-12 py-3">
                      <p className={Styles.card_text_features}>
                        Learn with real work experience and get guaranteed
                        placement as a Full-Stack or Backend Developer at
                        product-based companies.
                      </p>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/placement-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Important Pointers on Core CS subjects
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/placement-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Access to PYQs of Big Companies
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/placement-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Specific Bootcamp for Interviews
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/placement-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Quant Preparation
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 px-lg-5 mt-5">
              <div className={`card ${Styles.card_mod_features}`}>
                <div className="card-body py-4">
                  <div className="row px-2">
                    <div className="col-3 my-auto">
                      <img
                        src="/img/Home-Page/SWITCH JOB.webp"
                        width="80"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <p className={Styles.card_head_features}>JOB SWITCH</p>
                      <p className={Styles.card_subhead_features}>In any Job</p>
                    </div>
                    <div className="col-12 py-3">
                      <p className={Styles.card_text_features}>
                        Learn with real work experience and get guaranteed
                        placement as a Full-Stack or Backend Developer at
                        product-based companies.
                      </p>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/job-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Sessions after 7pm.
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/job-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Recording available for Live Classes
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/job-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Vast mentor network for getting referrals
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/job-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Regular contests to keep you in check.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center mt-5">
            <div className="col-12">
              <p className={Styles.features_ts_bottom}>
                Join to train like a champion in an ‘Out of Comfort’ challenging
                regime
              </p>
              <a className={`btn ${Styles.btn_checkout} px-4 mt-2`}>
                Checkout For More Details
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={`${Styles.mock_bg} mt-5`}>
        <div className="container">
          <div className="row">
            <div className={`col-md-4 text-start ${Styles.hide_sm}`}>
              <img
                src="/img/Home-Page/coding-interviews.webp"
                width="300"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-8 my-auto text-center text-lg-start">
              <p className={`${Styles.course_head} mt-5 mt-lg-0`}>
                Coding Test are different from Interviews
              </p>
              <img
                src="/img/Home-Page/coding-interviews.webp"
                width="200"
                className={`img-fluid ${Styles.hide_lg} py-2`}
                alt=""
              />
              <p className={`${Styles.course_subhead} pb-3`}>
                Practice
                <span className={Styles.mock_promo_ts}>50+ Companies</span>{" "}
                Previous Year questions curated at{" "}
                <span className={Styles.mock_promo_ts}>Algozenith Mock</span> !
              </p>
              <div className="d-flex justify-content-sm-center justify-content-lg-start pt-1 mb-5 mb-lg-0">
                <Link
                  to="/premium/#join-today"
                  className={`${Styles.btn_join_now} btn px-md-4`}
                >
                  Join Today
                </Link>
                <button
                  className={`${Styles.Watch_btn} border-0 bg-transparent ms-5`}
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                >
                  <img
                    src="/img/Home-Page/video-paly.svg"
                    className="img-fluid me-2"
                    width="40"
                    alt=""
                  />
                  <span className={Styles.highlight}>Watch Video</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`pt-5 ${Styles.form_bg}`}>
        <QueryForm />
      </section>

      <VideoModal />
    </>
  );
};

export default HomePage;
