import React from "react";
import { Urls } from "../../../constants/Urls";
import Styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={`container-fluid ${Styles.footer_color}`}>
      <div className={`row py-4 px-4 `}>
        <div className="col-12 col-md-6 col-lg-4 text-center text-md-start">
          <h5>ALGOZENITH</h5>
          <p className="mt-4">
            Plot No 365, Om Nagar, Behind Power House, <br /> Nagpur,
            Maharashtra 440009, India
          </p>
        </div>
        <div
          className={`col-12 col-md-6 col-lg-3 text-center text-md-start mt-5 mt-md-0 ${Styles.footer_links}`}
        >
          <h5 className="mb-4">USEFUL LINKS</h5>
          <p>
            <a className={Styles.footer_links} href={Urls.Social.MediumUrl()}>
              Blogs
            </a>
          </p>
          <p>
            <a
              className={Styles.footer_links}
              href={Urls.Legal.PrivacyPolicyUrl()}
            >
              Privacy Policy
            </a>
          </p>
          <p>
            <a
              className={Styles.footer_links}
              href={Urls.Legal.TermsAndConditionsUrl()}
            >
              Terms & Conditions
            </a>
          </p>
          <p>
            <a
              className={Styles.footer_links}
              href={Urls.Legal.PriceAndRefundPolicyUrl()}
            >
              Price & Refund Policy
            </a>
          </p>
          <a className={Styles.footer_links} href="">
            Bug Bounty
          </a>
        </div>
        <div className="col-12 col-md-6 col-lg-3 text-center text-md-start mt-5 mt-md-0">
          <h5>FOLLOW US ON</h5>
          <div className="d-flex justify-content-center justify-content-md-start mt-4">
            <a
              className={Styles.icons_mod}
              href={Urls.Social.FacebookUrl()}
            >
              <i className="fa-brands fa-square-facebook me-3"></i>
            </a>
            <a
              className={Styles.icons_mod}
              href={Urls.Social.YoutubeUrl()}
            >
              <i className="fa-brands fa-youtube ms-5"></i>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-5">
            <a
              className={Styles.icons_mod}
              href={Urls.Social.InstagramUrl()}
            >
              <i className="fa-brands fa-instagram me-3"></i>
            </a>
            <a
              className={Styles.icons_mod}
              href={Urls.Social.LinkedInUrl()}
            >
              <i className="fa-brands fa-linkedin-in ms-5"></i>
            </a>
          </div>
        </div>
      </div>

      <div className={`row ${Styles.copy} text-center pt-3`}>
        <div className="col-12">
          <p>
            &#169; Copyright 2023. AlgoZenith Technologies Pvt Ltd. All Rights
            Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
