import api from "./ApiService";

const ApplyCoupon = (coupon, amount) => {
  return api.get(`/users/coupon/${coupon}/details?original_amount=${amount}`);
};

const CreateOrder = (order) => {
  return api.post(`/users/orders`, order);
};

const UpdateSuccessOrder = (order, body) => {
  return api.post(`/users/orders/${order.order_id}/success`, body);
};

const CheckoutService = {
  CreateOrder,
  ApplyCoupon,
  UpdateSuccessOrder,
};

export default CheckoutService;
