import React, { useState } from "react";
import Styles from "./QueryForm.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const QueryForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!(name && email && subject && description)) {
      toast.error("One or more fields empty ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!email.includes("@")) {
      toast.error("Enter Valid Mail Id", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const query = {
        body: description,
        email: email,
        course: subject,
        name: name,
      };
      axios
        .post("https://algozenith.com/api/v1/users/query", query)
        .then((res) => {
          toast.success("Successfully Submitted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          toast.error("Form Submisson Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      setName("");
      setEmail("");
      setSubject("");
      setDescription("");
    }
  };

  return (
    <>
      <div className="container pb-5">
        <ToastContainer />
        <div className="row">
          <div className="col-12">
            <p className={Styles.contact_head}>
              {" "}
              <span className={Styles.bottom}>Contact </span>Us
            </p>
          </div>
          <div className="col-12 mt-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label className={Styles.label_ts} htmlFor="floatingInput">
                Name
              </label>
            </div>
            <div className="form-floating mt-3">
              <input
                type="email"
                className="form-control"
                id="floatingPassword"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className={Styles.label_ts} htmlFor="floatingPassword">
                Email
              </label>
            </div>
            <div className="form-floating mt-3">
              <input
                type="text"
                className="form-control"
                id="floatingPassword"
                placeholder="Query Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <label className={Styles.label_ts} htmlFor="floatingPassword">
                Query Subject
              </label>
            </div>
            <div className="form-floating mt-3">
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                id="floatingTextarea2"
                style={{ height: "170px" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              <label className={Styles.label_ts} htmlFor="floatingTextarea2">
                Describe your query
              </label>
            </div>
          </div>
          <div className="col-12 text-end mt-5">
            <button
              className={`btn ${Styles.submit_btn} px-4`}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryForm;
