import "./App.css";
import NavBar from "./components/Layout/Header/Header";
import { Routes, Route, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

// Page Imports
import HomePage from "./pages/HomePage/HomePage";
import PremiumPage from "./pages/PremiumPage/PremiumPage";
import Faqs from "./pages/Faqs/Faqs";
import Footer from "./components/Layout/Footer/Footer";
import Results from "./pages/Results/Results";
import Checkout from "./pages/Checkout/Checkout";
import NotFound from "pages/ErrorPages/NotFound";
import InternalServerError from "pages/ErrorPages/InternalServerError";

const App = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  setCookie("password", "pwd", { path: "/" });
  setCookie("Name", "name", { path: "/" });
  // console.log(cookies.azref);
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route index path="/home" element={<HomePage />} />
        <Route path="/premium" element={<PremiumPage />} />
        <Route path="/faq" element={<Faqs />} />
        <Route path="/results" element={<Results />} />
        {/* <Route path="/checkout/:courseid" element={<Checkout />} /> */}
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/500" element={<InternalServerError />} />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
