import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import QueryForm from "../../components/QueryForm/QueryForm";
import useDocumentTitle from "../../UserDocumentTitle";
import Styles from "./PremiumPage.module.css";
import { premium } from "./TestimonialData";
import TimerCountdown from "./TimerCountdown";
import { Urls } from "../../constants/Urls";

const PremiumPage = () => {
  useDocumentTitle("Premium | Algozenith");
  const [searchParams, setSearchParams] = useSearchParams();
  const invitekey = searchParams.get("invitekey");

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const getDate = () => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <section className={Styles.hero_bg}>
        <div className="container py-5">
          <div className="row text-center">
            <div className="col-12">
              <p className={Styles.hero_head_ts}>
                Algo<span className={Styles.head_underline}>zenith Pre</span>
                mium
              </p>
              <p className={Styles.hero_subhead_ts}>
                Takes you DSA & CP Skills to the Zenith!
              </p>
              <img src="" alt="" />
              <div className="d-flex justify-content-center">
                <Link
                  to="/premium/#join-today"
                  className={`btn ${Styles.btn_join_now} px-4`}
                >
                  {" "}
                  Join Now{" "}
                </Link>
                <Link
                  to="/premium/#curriculum"
                  className={`btn ${Styles.btn_cirriculum} px-4 ms-3`}
                >
                  {" "}
                  Curriculum{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-12 mb-4">
              <p className={Styles.dream_head_ts}>
                We make your{" "}
                <span className={Styles.dream_underline}>dreams come</span> true
                !
              </p>
            </div>
            <div className="col-md-5 mt-5  order-1 order-md-0 my-md-auto">
              <div className="row">
                <div className="col-2">
                  <img
                    src="/img/Premium-Page/hight-package.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-10 text-start">
                  <p className={Styles.dream_ts}>
                    {" "}
                    Highest Fresher Package <br /> <span>1.2 Cr</span> per year
                    (in India)
                  </p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2">
                  <img
                    src="/img/Premium-Page/rank.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-10 text-start">
                  <p className={Styles.dream_ts}>
                    {" "}
                    {/* Total Offers value bagged <br /> 20 Cr+ last year! */}
                    Multiple under 100 Ranks in Kickstart last year!
                  </p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2">
                  <img
                    src="/img/Premium-Page/salary-package.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-10 text-start">
                  <p className={Styles.dream_ts}>
                    {" "}
                    {/* Total Offers value bagged <br /> 20 Cr+ last year! */}
                    Internship and Placement in 100+ top Product-Based
                    companies.
                  </p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2">
                  <img
                    src="/img/Premium-Page/trust.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-10 text-start">
                  <p className={Styles.dream_ts}>
                    {" "}
                    {/* Total Offers value bagged <br /> 20 Cr+ last year! */}
                    Trusted by even IITians for their Career prep.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7 order-0 order-md-1 my-auto">
              {/* style={{"--bs-aspect-ratio": "10%"}} */}
              <div className="ratio ratio-16x9">
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/742647006?h=7f1cb15a1b"
                  width="570"
                  height="360"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 text-center">
              <p className={Styles.dreams_bottom_ts}>
                Some of the many student results. Check out more -
                <Link to="/results" className={Styles.dream_link_ts}>
                  HERE
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={`mt-5 ${Styles.projects_bg}`}>
        <div className="container pb-5 pt-4">
          <div className="row text-center">
            <div className="col-12 mb-4">
              <p className={Styles.instructor_head_ts}>
                Projects an
                <span className={Styles.instructor_underline}>
                  d Hackathon for Y
                </span>
                our Resume
              </p>
            </div>
          </div>

          <div className="row text-center">
            <div className="col-12 col-md-4">
              <div className={`card mx-auto ${Styles.problems_card_mod}`}>
                <p className={`${Styles.card_head} mt-4`}>
                  Rubiks Cube Solver <br /> (Capstone Project)
                </p>
                <div className="card-body text-center">
                  <div className={`card mx-auto ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      <img
                        src="/img/Premium-Page/rubix-cube.svg"
                        className="img-fluid py-3"
                        width="120"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-5 mt-md-0">
              <div className={`card mx-auto ${Styles.problems_card_mod}`}>
                <p className={`${Styles.card_head} mt-4`}>
                  Movie Recommendation <br /> Desktop App
                </p>
                <div className="card-body text-center">
                  <div className={`card mx-auto ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      <img
                        src="/img/Premium-Page/movie.svg"
                        className="img-fluid py-3"
                        width="120"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-5 mt-md-0">
              <div className={`card mx-auto ${Styles.problems_card_mod}`}>
                <p className={`${Styles.card_head} mt-4`}>
                  Search Engine <br /> Web App
                </p>
                <div className="card-body text-center">
                  <div className={`card mx-auto ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      <img
                        src="/img/Premium-Page/search-engine.svg"
                        className="img-fluid py-3"
                        width="120"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5 pt-4">
            <div className="col-md-7 my-auto">
              <div className="row g-0">
                <div className="col-1">
                  <img
                    src="/img/Premium-Page/check-icon.svg"
                    width={35}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-11">
                  <p className={Styles.features_text}>
                    Create Interesting Projects in Internal Hackathons to get a
                    A Resume
                  </p>
                </div>
              </div>

              <div className="row g-0">
                <div className="col-1">
                  <img
                    src="/img/Premium-Page/check-icon.svg"
                    width={35}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-11">
                  <p className={Styles.features_text}>
                    Certificate and CV + Interview Prep related to the project
                    will be covered from our end !
                  </p>
                </div>
              </div>

              <div className="row g-0">
                <div className="col-1">
                  <img
                    src="/img/Premium-Page/check-icon.svg"
                    width={35}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-11">
                  <p className={Styles.features_text}>
                    There are Prizes to be won in the Hackathons, including 100%
                    course sponsorship
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-5 mt-5 mt-md-0 text-center">
              <div className="ratio ratio-16x9 mb-3">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/7LQArO7yCug"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="d-grid gap-2">
                <Link
                  to="/premium/#join-today"
                  className={`btn btn ${Styles.btn_project} px-5 py-2`}
                >
                  See More Submissions{" "}
                  <i className="fa-solid fa-right-long ps-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="curriculum" className="mt-5 pb-lg-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-12 mb-4">
              <p className={Styles.instructor_head_ts}>
                Cour
                <span className={Styles.instructor_underline}>se Curric</span>
                ulum
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7 col-lg-8">
              <div className="row">
                <div className="col-4 my-auto">
                  <img
                    src="/img/Premium-Page/day-wise-plan.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-8 my-auto">
                  <p className={Styles.dream_ts}>
                    A day wise plan with targets for each day to complete all
                    the curriculum in a smooth way (Including Live Classes)
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-8 my-auto">
                  <p className={Styles.dream_ts}>Each day has sections:</p>
                  <ul className={`list-inline ${Styles.prob_ts}`}>
                    <li className="list-inline-item">1. Minimal</li>
                    <li className="list-inline-item">2. Standard</li>
                    <li className="list-inline-item">3. Achiever</li>
                  </ul>
                  <p className={Styles.dream_ts}>
                    Depending on how much time you have
                  </p>
                </div>
                <div className="col-4 my-auto">
                  <img
                    src="/img/Premium-Page/problesm-list.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-4 text-center mx-auto my-auto">
              <div
                className={`card py-4 mt-5 mt-md-0 ${Styles.cirricumlum_card}`}
              >
                <div className="card-body">
                  <p className={Styles.dream_ts}>
                    A high growth training Designed to take your career and
                    ratings to the sky roof
                  </p>
                  <p className={`mt-3 ${Styles.prob_ts}`}>
                    Here are curriculum details:
                  </p>
                  <Link
                    to="/premium/#join-today"
                    className={`btn btn ${Styles.btn_project} px-5 py-2`}
                  >
                    AZ Premium Training{" "}
                    <i className="fa-solid fa-right-long ps-2"></i>
                  </Link>
                  <p className={`mt-4 ${Styles.note}`}>
                    Note: This also includes plan for Bootcamp, Interview
                    Bootcamp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <p className={Styles.instructor_head_ts}>
                Instru
                <span className={Styles.instructor_underline}>
                  ctors - Cum -M
                </span>
                entors
              </p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-xl-6">
              <div className={`card ${Styles.instructor_card_mod}`}>
                <div className="row g-0">
                  <div className="col-5 col-md-3 col-lg-2 col-xl-5">
                    <img
                      src="/img/Premium-Page/vivek.webp"
                      className={`img-fluid rounded-start ${Styles.mentor_img}`}
                      alt="..."
                    />
                  </div>
                  <div className="col-7 col-md-9 col-lg-10 col-xl-7">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mt-2">
                        <h5 className={Styles.instructor_name}>Vivek Gupta</h5>
                        <img
                          src="/img/Premium-Page/google.svg"
                          className="img-fluid ms-2"
                          width="70"
                          alt=""
                        />
                      </div>
                      <p className={`mt-3 ${Styles.card_instructor_text}`}>
                        Vivek has an undying passion for Competitive Programming
                        and has qualified for ICPC World finals. He has received
                        amazing feedbacks for his teaching skills and
                        methodology.
                      </p>
                      <div className="position-absolute bottom-0 end-0">
                        <a
                          href={Urls.Intructors.VivekYoutubeUrl()}
                          target="_blank"
                          rel="noreferrer"
                          className="btn"
                        >
                          <i
                            className={`${Styles.youtube_social_media} fa-brands fa-youtube`}
                          ></i>
                        </a>
                        <a
                          href={Urls.Intructors.VivekLinkedinUrl()}
                          target="_blank"
                          rel="noreferrer"
                          className="btn"
                        >
                          <i
                            className={`${Styles.linkedin_social_media} fa-brands fa-linkedin`}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 mt-4 mt-xl-0">
              <div className={`card ${Styles.instructor_card_mod}`}>
                <div className="row g-0">
                  <div className="col-5 col-md-3 col-lg-2 col-xl-5">
                    <img
                      src="/img/Premium-Page/nitish.webp"
                      className={`img-fluid rounded-start ${Styles.mentor_img}`}
                      alt="..."
                    />
                  </div>
                  <div className="col-7 col-md-9 col-lg-10 col-xl-7">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mt-2">
                        <h5 className={Styles.instructor_name}>Nitish </h5>
                        <img
                          src="/img/Premium-Page/google.svg"
                          className="img-fluid ms-2"
                          width="70"
                          alt=""
                        />
                      </div>
                      <p className={`mt-3 ${Styles.card_instructor_text}`}>
                        Nitish is a competitive programmer who loves solving DP
                        and Graph problems. He has qualified for ICPC World
                        Finals. He has interned at Google and currently works at
                        Google.
                      </p>
                      <div className="position-absolute bottom-0 end-0">
                        <a
                          href={Urls.Intructors.NitishLinkedinUrl()}
                          target="_blank"
                          rel="noreferrer"
                          className="btn"
                        >
                          <i
                            className={`${Styles.linkedin_social_media} fa-brands fa-linkedin`}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-xl-6">
              <div className={`card ${Styles.instructor_card_mod}`}>
                <div className="row g-0">
                  <div className="col-5 col-md-3 col-lg-2 col-xl-5">
                    <img
                      src="/img/Premium-Page/shubham.webp"
                      className={`img-fluid rounded-start ${Styles.mentor_img}`}
                      alt="..."
                    />
                  </div>
                  <div className="col-7 col-md-9 col-lg-10 col-xl-7">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mt-2">
                        <h5 className={Styles.instructor_name}>Shubham</h5>
                        <img
                          src="/img/Premium-Page/google.svg"
                          className="img-fluid ms-2"
                          width="70"
                          alt=""
                        />
                      </div>
                      <p className={`mt-3 ${Styles.card_instructor_text}`}>
                        Shubham is a software engineer at Google and competitive
                        programming expert, who has qualified for ICPC World
                        Finals 2020 and SnackDown Finals 2021. He is a dedicated
                        mentor at AlgoZenith, helping students improve their
                        programming abilities and reach their full potential.
                      </p>
                      <div className="position-absolute bottom-0 end-0">
                        <a
                          href={Urls.Intructors.ShubhamLinkedinUrl()}
                          target="_blank"
                          rel="noreferrer"
                          className="btn"
                        >
                          <i
                            className={`${Styles.linkedin_social_media} fa-brands fa-linkedin`}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 mt-4 mt-xl-0">
              <div className={`card ${Styles.instructor_card_mod}`}>
                <div className="row g-0">
                  <div className="col-5 col-md-3 col-lg-2 col-xl-5">
                    <img
                      src="/img/Premium-Page/rohan.webp"
                      className={`img-fluid rounded-start ${Styles.mentor_img}`}
                      alt="..."
                    />
                  </div>
                  <div className="col-7 col-md-9 col-lg-10 col-xl-7">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mt-2">
                        <h5 className={Styles.instructor_name}>Rohan</h5>
                        <img
                          src="/img/Premium-Page/google.svg"
                          className="img-fluid ms-2"
                          width="70"
                          alt=""
                        />
                      </div>
                      <p className={`mt-2 ${Styles.card_instructor_text}`}>
                        Rohan is an expert in Full Stack Development and is an
                        expert in various frameworks and technologies. In
                        addition to his technical abilities, Rohan is also a
                        dedicated mentor, committed to helping others grow and
                        develop their skills. With his ability to lead,
                        strategize and execute, Rohan is a key player in the
                        growth and success of AlgoZenith!
                      </p>
                      <div className="position-absolute bottom-0 end-0">
                        <a
                          href={Urls.Intructors.RohanLinkedinUrl()}
                          target="_blank"
                          rel="noreferrer"
                          className="btn"
                        >
                          <i
                            className={`${Styles.linkedin_social_media} fa-brands fa-linkedin`}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="join-today"
        className={`pricing-bg mt-5 ${Styles.course_bg}`}
      >
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 text-center">
              <p className={Styles.instructor_head_ts}>
                Featu
                <span className={Styles.instructor_underline}>res and Pri</span>
                cing
              </p>
              <p className={Styles.sub_features_ts}>
                Invest in skills and earn atleast 10x of that every month
              </p>
            </div>
            <div className="col-12 mt-4 text-center text-md-start">
              <p className={Styles.select_batch}>
                <span className={Styles.select_underline}>Sele</span>ct a Batch
              </p>
            </div>
            <div className="col-md-6 px-lg-4 mt-4">
              <div className={`card px-3 ${Styles.card_mod_batch}`}>
                <div className="card-body text-center">
                  <p className={Styles.batch_head}>AZ-201 Triumph Batch</p>

                  <p className={`text-start pt-3 ${Styles.features_red}`}>
                    <i
                      className={`fa-regular fa-calendar pe-2 ${Styles.icon_bg_blue}`}
                    ></i>
                    Start Date :{" "}
                    <span className={`${Styles.batch_details_ts}`}>
                      12th December 2022
                    </span>
                  </p>
                  <p className={`text-start pt-3 ${Styles.features_red}`}>
                    <i
                      className={`fa-regular fa-clock pe-2 ${Styles.icon_bg_blue}`}
                    ></i>
                    Limited Time Offer :{" "}
                    <span className={`${Styles.batch_details_ts}`}>
                      Get Extra 30 Mock Company-Wise Placement Tests worth INR
                      6000 Absolutely Free!
                    </span>
                  </p>

                  <p className={`text-start pb-3 ${Styles.batch_details_ts}`}>
                    <i
                      className={`fa-solid fa-percent pe-2 ${Styles.icon_bg_blue}`}
                    ></i>
                    <span className={`${Styles.features_red}`}>
                      Hurry up offer Valid Till :{" "}
                    </span>{" "}
                    {getDate()}
                  </p>
                  <div className="d-grid gap-2">
                    <Link
                      to={`/checkout?coursecode=az201triumph&invitekey=${invitekey}`}
                      className={`btn py-2 ${Styles.btn_mod_bg}`}
                      type="button"
                    >
                      Join Today <i className="fa-solid fa-right-long ps-2"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-lg-4 mt-4">
              <div className={`card px-3 ${Styles.card_mod_batch}`}>
                <div className="card-body text-center">
                  <p className={Styles.batch_head}>AZ-201 Summit Batch</p>

                  <p className={`text-start pt-3 ${Styles.features_red}`}>
                    <i
                      className={`fa-regular fa-calendar pe-2 ${Styles.icon_bg_blue}`}
                    ></i>
                    Start Date :{" "}
                    <span className={`${Styles.batch_details_ts}`}>
                      4th September 2022
                    </span>
                  </p>
                  <p className={`text-start pt-3 ${Styles.features_red}`}>
                    <i
                      className={`fa-regular fa-clock pe-2 ${Styles.icon_bg_blue}`}
                    ></i>
                    Limited Time Offer :{" "}
                    <span className={`${Styles.batch_details_ts}`}>
                      Get Extra 30 Mock Company-Wise Placement Tests worth INR
                      6000 Absolutely Free!
                    </span>
                  </p>

                  <p className={`text-start pb-3 ${Styles.batch_details_ts}`}>
                    <i
                      className={`fa-solid fa-percent pe-2 ${Styles.icon_bg_blue}`}
                    ></i>
                    <span className={`${Styles.features_red}`}>
                      Hurry up offer Valid Till :{" "}
                    </span>{" "}
                    {getDate()}
                  </p>
                  <div className="d-grid gap-2">
                    <Link
                      to={`/checkout?coursecode=az201summit&invitekey=${invitekey}`}
                      className={`btn py-2 ${Styles.btn_mod_bg}`}
                      type="button"
                    >
                      Join Today <i className="fa-solid fa-right-long ps-2"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center text-md-start mb-4 mt-5">
              <p className={Styles.select_batch}>
                <span className={Styles.select_underline}>Feat</span>ures
              </p>
            </div>
            <div className={`col-4 ${Styles.features_ts}`}>
              <i className={`fa-solid fa-video pe-3 ${Styles.icon_bg}`}></i>
              <span className={Styles.features_orange}>100+</span> Hrs Live
              Content
            </div>
            <div className={`col-4 text-center ${Styles.features_ts}`}>
              <i
                className={`fa-regular fa-lightbulb pe-2  ${Styles.icon_bg}`}
              ></i>
              <span className={Styles.features_orange}>50+</span> Hrs Contest
            </div>
            <div className={`col-4 text-center ${Styles.features_ts}`}>
              <i className={`fa-solid fa-medal pe-2 ${Styles.icon_bg}`}></i>
              <span className={Styles.features_orange}>500+</span> Problems
            </div>
            <div className="col-md-6 mt-5">
              <div className="row g-0">
                <div className="col-1">
                  <img
                    src="/img/Premium-Page/check-icon.svg"
                    width={35}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-11">
                  <p className={Styles.features_text}>
                    Includes a Interview Bootcamp and Mentor Network Access
                    included
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3 mt-md-5">
              <div className="row g-0">
                <div className="col-1">
                  <img
                    src="/img/Premium-Page/check-icon.svg"
                    width={35}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-11">
                  <p className={Styles.features_text}>
                    Access of content for an year (paid extensions available)
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row g-0">
                <div className="col-1">
                  <img
                    src="/img/Premium-Page/check-icon.svg"
                    width={35}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-11">
                  <p className={Styles.features_text}>
                    Get 20 Mock Tests Free if you Join as an early bird before
                    1st AUGUST 2022
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row g-0">
                <div className="col-1">
                  <img
                    src="/img/Premium-Page/check-icon.svg"
                    width={35}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-11">
                  <p className={Styles.features_text}>No Cost EMI Available</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <p className={Styles.instructor_head_ts}>
                Still{" "}
                <span className={Styles.instructor_underline}>
                  not sure if its
                </span>{" "}
                worth it ?
              </p>
              {/* <p className={Styles.sub_head_youtube}>
                3 + 4 + 5 + 4 + 3 + 5 = 25 Weeks of Effort for Excellence
              </p> */}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <div className={`card ${Styles.linked_list_card}`}>
                <div className={`card-body ${Styles.card_body_mod}`}>
                  <div className="row">
                    <div className={`col-8 ${Styles.youtube_border}`}>
                      <div className="row pt-2 g-0">
                        <div className="col-2 my-auto">
                          <img
                            src="/img/Premium-Page/demo-linkedlist.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="col-10 ps-2 my-auto">
                          <p className={Styles.youtube_card_head}>
                            Linked List Demo Training
                          </p>
                          <p className={`${Styles.youtube_card_subhead} pt-2`}>
                            - Prepare for Interview
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 my-auto">
                      <a
                        href={Urls.PremiumPage.DemoVideoURL()}
                        target="_blank"
                        rel="noreferrer"
                        className={`${Styles.vist_ts} ms-3`}
                      >
                        Visit Now
                        <i className="fa-solid fa-right-long ms-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`card ${Styles.prefix_sum_card} mt-5`}>
                <div className="card-body">
                  <div className="row">
                    <div className={`col-8 ${Styles.youtube_border}`}>
                      <div className="row pt-2 g-0">
                        <div className="col-2">
                          <img
                            src="/img/Premium-Page/demo-linkedlist.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="col-10 ps-1">
                          <p className={Styles.youtube_card_head}>
                            Prefix Sum Demo Training{" "}
                          </p>
                          <p className={`${Styles.youtube_card_subhead} `}>
                            - for CP & Coding tests
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 my-auto">
                      <a
                        href={Urls.PremiumPage.DemoVideoURL()}
                        target="_blank"
                        rel="noreferrer"
                        className={`${Styles.vist_ts} ms-3`}
                      >
                        Visit Now
                        <i className="fa-solid fa-right-long ms-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`card ${Styles.prefix_bitmask_card} mt-5`}>
                <div className="card-body">
                  <div className="row">
                    <div className={`col-8 ${Styles.youtube_border}`}>
                      <div className="row pt-2 g-0">
                        <div className="col-2">
                          <img
                            src="/img/Premium-Page/demo-linkedlist.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="col-10 ps-1">
                          <p className={Styles.youtube_card_head}>
                            Dynamic Programming Workshop
                          </p>
                          <p className={`${Styles.youtube_card_subhead}`}>
                            - for DSA and CP
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 my-auto">
                      <a
                        href={Urls.PremiumPage.DPWorkshopURL()}
                        target="_blank"
                        rel="noreferrer"
                        className={`${Styles.vist_ts} ms-3`}
                      >
                        Visit Now
                        <i className="fa-solid fa-right-long ms-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 my-lg-auto">
              <a
                href="https://www.youtube.com/channel/UC_xBkUq2z-rbp2mbZal-0cw"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <img
                  src="/img/Premium-Page/vg-youtube.webp"
                  className="img-fluid mt-5 mt-xl-0"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={`${Styles.timmer_session} mt-5`}>
        <div className="container py-5">
          <div className="row text-center text-white">
            <div className="col-12">
              <TimerCountdown />
              <Link
                to="/premium/#join-today"
                className={`btn ${Styles.btn_timmer} px-4 py-2`}
              >
                Select Batch Join Now to New Journey
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5">
        <div className="container-fluid">
          <div className="row pb-4">
            <div className="col-12 text-center">
              <p className={Styles.instructor_head_ts}>
                What Our{" "}
                <span className={Styles.instructor_underline}>
                  {" "}
                  Students Say{" "}
                </span>{" "}
                About Us
              </p>
            </div>
          </div>

          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="row">
              <div className="col-1">
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <i className="fa-solid fa-chevron-left text-dark"></i>
                  <span className="visually-hidden">Previous</span>
                </button>
              </div>
              <div className="col-10 px-4">
                <div className="carousel-inner">
                  {premium.map((data, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className={`card ${Styles.card_shadow}`}>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 text-center my-auto">
                                  <img
                                    src={data.set1.photo}
                                    className="img-fluid"
                                    style={{
                                      height: "200px",
                                      borderRadius: "10px",
                                    }}
                                    alt=""
                                  />
                                </div>
                                <div className="col-lg-8">
                                  <p
                                    className={`pt-3 ${Styles.testimonial_text}`}
                                  >
                                    {data.set1.testimonial.slice(0, 500)} ...
                                  </p>
                                  <hr className={Styles.hr} />
                                  <div
                                    className="d-flex justify-content-between pt-3"
                                    style={{ marginTop: "-13px" }}
                                  >
                                    <div>
                                      <p className={Styles.Student_name}>
                                        {data.set1.name}
                                      </p>
                                      {/* <p className={Styles.Student_Course}>
                                        Enrolled in AZ-201
                                      </p> */}
                                    </div>
                                    <div className="my-auto">
                                      <img
                                        src={data.set1.company_logo}
                                        className="img-fluid"
                                        alt=""
                                        width={100}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5 pb-5">
                        <div className="col-12">
                          <div className={`card ${Styles.card_shadow}`}>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 text-center my-auto">
                                  <img
                                    src={data.set2.photo}
                                    className="img-fluid"
                                    style={{
                                      height: "200px",
                                      borderRadius: "10px",
                                    }}
                                    alt=""
                                  />
                                </div>
                                <div className="col-lg-8">
                                  <p
                                    className={`pt-3 ${Styles.testimonial_text}`}
                                  >
                                    {data.set2.testimonial.slice(0, 500)} ...
                                  </p>
                                  <hr className={Styles.hr} />
                                  <div
                                    className="d-flex justify-content-between pt-3"
                                    style={{ marginTop: "-13px" }}
                                  >
                                    <div>
                                      <p className={Styles.Student_name}>
                                        {data.set2.name}
                                      </p>
                                      {/* <p className={Styles.Student_Course}>
                                        AZ Premium
                                      </p> */}
                                    </div>
                                    <div>
                                      <img
                                        src={data.set2.company_logo}
                                        className="img-fluid"
                                        width={100}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-1">
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <i className="fa-solid fa-chevron-right text-dark"></i>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className={`pt-5 ${Styles.query_form_bg}`}>
        <QueryForm />
      </section>
    </>
  );
};

export default PremiumPage;
