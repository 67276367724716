import React, { useState } from "react";
import { FaqContext } from "../../context/FaqContext";

//Imports
import Styles from "./Faqs.module.css";
import FaqsAccordion from "./FaqsAccordion/FaqsAccordion";
import FaqHead from "./FaqsHead/Faqshead";
import { faq } from "./FaqData";
import useDocumentTitle from "../../UserDocumentTitle";

const Faqs = () => {
  // Setting up tab title
  useDocumentTitle("Faqs | Algozenith");

  const [faqData, setFaqData] = useState(faq);
  const [faqDataHandle, setFaqDataHandle] = useState(1);

  const faqDataState = {
    faqData,
    setFaqDataHandle,
    faqDataHandle,
  };

  return (
    <FaqContext.Provider value={faqDataState}>
      <div className={`container-fluid ${Styles.faq_hero}`}>
        <div className="row py-5 text-center">
          <div className="col-12">
            <p className={Styles.faq_hero_ts}>Got a Question?</p>
            <p className={Styles.faq_hero_subts}>
              You are not the only one! See how we have answered the most common
              questions.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-3 pb-5">
        <div className="row mt-5">
          <div className="col-md-4">
            <FaqHead />
          </div>
          <div className="col-md-8 mt-5 mt-md-0">
            <FaqsAccordion />
          </div>
        </div>
      </div>
    </FaqContext.Provider>
  );
};

export default Faqs;
