import React from "react";
import Styles from "../Checkout.module.css";

const CheckoutCart = ({ amount, discount }) => {
  return (
    <>
      <div className="card">
        <div className={`card-header ${Styles.cardHeader}`}>
          <p className={Styles.orders}>Order Summary</p>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between mt-2">
            <p className={Styles.sideHead}>Sub-Total</p>
            <p className={Styles.price}>₹ {amount}</p>
          </div>
          {discount ? (
            <div className="d-flex justify-content-between">
              <p className={Styles.sideHead}>Discount</p>
              <p className={Styles.price}>₹ ({discount})</p>
            </div>
          ) : (
            ""
          )}
          <hr className={Styles.hr} />
          <div className="d-flex justify-content-between">
            <p className={Styles.total}>Total</p>
            <p className={Styles.total}>₹ {amount - discount}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutCart;
