export const premium = [
  {
    set1: {
      name: "Pranav Mishra",
      testimonial:
        "I joined the AZ-201 to prepare myself for the upcoming internship season, and I was a complete newbie in the world of coding. But soon, with the help of this well-structured course, the exhaustive problem set of each and every topic, and the excellent teachings given by the teachers helped me a lot to vanish the fear of coding and within a very short span of time, I could see myself in a position to easily come-up with the solution of any new question given to me in the interviews. The whole community of AlgoZenith always motivated me to work harder everyday on coding, which eventually helped me to crack Goldman Sachs on Day 1 itself. I will always be thankful to the members of AlgoZenith for building such platform and helping average students like me to easily crack one of their dream companies.I would highly recommend this course to anyone who is just starting his preparation or someone who believes that there is something missing in his preparation. I can guarantee that AlgoZenith will convert you from bronze to gold, and if you are gold, then to diamond.",
      company_logo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/GS.png",
      photo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_2_PRANAV.jpg",
      color: "rgba(100,168,241,0.8)",
    },
    set2: {
      name: "Vedavittalvihar Terala",
      testimonial:
        "This is one of the best structured, fun filled, helpful course in my learning curve. Before, I was quite afraid of programming but the mentors here made it soo overwhelmed and joyful experiences with live sessions of tips and tricks. I am glad I found Algozenith. The guide mode helped me with time management, the contests, and mock tests made us prepared for the real world.",
      company_logo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/GS.png",
      photo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_3_VIHAR.jpg",
      color: "rgba(100,168,242,0.8)",
    },
  },
  {
    set1: {
      name: "Jayanth Yindukuri",
      testimonial:
        "In January, I realized the importance of CP in bagging a decent internship. I was clueless about where to start CP when I came across AlgoZenith. I started my journey with competitive programming with the AZ201 course. From C++ STL to Advanced Dynamic Programming, it has an excellent curriculum that laid the foundations for CP and helped me gain confidence in a short period. AZ was a matchless combo of splendid curriculum and exceptional mentor-ship. That's all you need! Also, it was so on point, and the mock interview was of tremendous help!",
      company_logo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/TOWER.png",
      photo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_17_JAYANTH.jpg",
      color: "rgba(67,87,138,0.8)",
    },
    set2: {
      name: "Debajit Chakraborty",
      testimonial:
        "Before taking this course I was just a beginner who aimlessly just gave contests, never upsolved(analyse the problems after giving the contest) and was below average. However this course gave it a direction with clearly defined topics, questions, contests at regular intervals and doubt sessions. There were so many things like binary search invariant which I used earlier without knowing why it was correct and the course taught me the importance of self analysis as done by regular contest discussions after contests. This definitely boosted my cp skills by huge amounts which would have otherwise taken huge number of hours, time waste on searching for content, aimlessly doing questions at a very minimal and reasonable cost. It would definitely provide to be a huge role behind the upcoming internships sessions and I am much more confident about my upcoming internships and placements than I was 3 months ago.",
      company_logo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/QUADEYE.png",
      photo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_18_DEBAJIT.jpg",
      color: "rgba(50,176,228,0.8)",
    },
  },
  {
    set1: {
      name: "Rajat Bachhawat",
      testimonial:
        "The problemsets are so meticulously curated that each problem has something new to teach you, and nothing is repetitive. Thus, I got amazing targeted practice of DS/Algo questions - which I did not find elsewhere. Not only do the mentors explain topics in the most easy to understand way possible, they also discuss the approach to intriguing problems interactively in live sessions, which helps in understanding concepts concretely. Also, the mentors are always available for doubt clearing and general advice. Without doubt, AlgoZenith's course was instrumental in building my problem solving aptitude, improving my competitive programming skills, and helping me get an internship.",
      company_logo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/QUADEYE.png",
      photo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_20_RAJAT.jpg",
      color: "rgba(50,176,230,0.8)",
    },
    set2: {
      name: "Anany Sharma",
      testimonial:
        "The question bank and the structure of Az-201 course benefitted me greatly. Vivek sir has a unique way of explaining concepts with multiple approaches, all while keeping it intuitive, which helped me in solving new questions. The doubt platform and the interview bootcamp were also quite helpful. AZ mock tests were quite close to the Real Coding Tests of Companies which helped me to prepare in a similar environment.",
      company_logo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/AMAZON.png",
      photo:
        "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_24_ANANY.jpg",
      color: "rgba(37,49,63,0.8)",
    },
  },
];
