import React from "react";
import Styles from '../Checkout.module.css'

const BatchNotFound = () => {
  return (
    <>
      <div className="container">
        <div className="row py-5 my-md-5">
            <div className="col-md-6 text-center order-sm-1 order-md-1 my-auto">
                <p className={`mt-5 mt-md-0  ${Styles.invalid_course}`}> Invalid Course </p>
                <p className={Styles.course_not_found}> Course Not Found </p>
                <p className={Styles.course_look}> Course your are looking for does not exist. </p>
                <button className={`btn px-4 mt-3 ${Styles.btnInvalidCourse}`}>Home</button>
            </div>
            <div className="col-md-6 text-center order-sm-0 order-md-1 my-auto">
                <img src="/img/Invalid-Page/invalid.svg" class="img-fluid" alt="" />
            </div>
        </div>
      </div>
    </>
  );
};

export default BatchNotFound;
