import React from "react";
import Styles from "./Results.module.css";
import { linkedinData, allStudents } from "./ResultsData";
import Masonry from "react-masonry-css";
import useDocumentTitle from "../../UserDocumentTitle";

const Results = () => {
  useDocumentTitle("Results | Algozenith");

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 2,
    500: 1,
  };

  return (
    <>
      <div className={`container-fluid ${Styles.results_hero}`}>
        <div className="row py-5">
          <div className="col-12 text-center">
            <p className={Styles.hero_text}>What our Students Say About Us</p>
            <p className={Styles.hero_subtext}>
              It’s a good idea to go through reviews of previous students to
              make an informed decision
            </p>
          </div>
        </div>
      </div>

      <div className={`container-fluid pb-5 ${Styles.testimonials_clean}`}>
        <div className={`row ${Styles.people}`}>
          {linkedinData.map((data, index) => (
            <div
              key={index}
              className={`col-md-6 col-lg-4 mt-5 ${Styles.item} ${Styles.box_padding}`}
            >
              <div className={Styles.box}>
                <p className={Styles.description}>{data.testimonial}</p>
              </div>
              <div className={Styles.author}>
                <img className={Styles.rounded_circle} src={data.photo} alt="img" />
                <h5 className={Styles.name}>{data.name}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className={Styles.my_masonry_grid}
          columnClassName={Styles.my_masonry_grid_column}
        >
          {allStudents.map((data) => (
            <div
              className={`${Styles.card} card shadow mt-4`}
              style={{ "border-radius": "15px" }}
            >
              <div
                className={Styles.Card_img}
                style={{
                  background: `linear-gradient(0deg, ${data.color}, ${data.color}), url(/img/results/background.png)`,
                }}
              >
                <img
                  src={data.photo}
                  className={`rounded-circle ${Styles.rounded_circle} img-fluid mb-3 mt-5 ml-2 shadow`}
                  width="300px"
                  alt=""
                />
              </div>
              <div className="row pt-3">
                <div className="col-12 text-end">
                  <img
                    src={data.company_logo}
                    className="img-fluid mb-2 pe-3"
                    style={{ width: "120px" }}
                    alt=""
                  />
                </div>
              </div>
              <div className="card-body">
                <div className="row px-3">
                  <div className="col-12">
                    <h2 className={Styles.card_title}>{data.name}</h2>
                  </div>
                </div>

                <p
                  className={`${Styles.cardtext} pt-2 px-3`}
                  style={{ textAlign: "justify !important" }}
                >
                  {data.testimonial}
                </p>
              </div>
            </div>
          ))}
        </Masonry>
      </div>
    </>
  );
};

export default Results;
