import React from "react";
import { BeatLoader } from "react-spinners";

import Styles from "../Checkout.module.css";

const UserDetails = ({
  firstNameState,
  firstNameChangeHandler,
  lastNameState,
  lastNameChangeHandler,
  phoneNoState,
  phoneNoChangeHandler,
  collegeState,
  collegeChangeHandler,
  coupon,
  setCoupon,
  handleCoupon,
  inviteKey,
  setInviteKey,
  loading,
  handleRayzorpay,
}) => {
  return (
    <>
      <div className="card mt-4">
        <div className="card-body">
          <p className={Styles.payment_ts}>Details</p>
          <hr className={Styles.hr} />
          <div className="row g-2 mt-4">
            <div className="col-md">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control ${
                    firstNameState.isValid === false ? `is-invalid` : ``
                  }`}
                  id="floatingInput"
                  placeholder="First Name"
                  value={firstNameState.val}
                  onChange={firstNameChangeHandler}
                />
                <div id="validationServer05Feedback" class="invalid-feedback">
                  Must be atleast 3 letters
                </div>
                <label
                  className={Styles.floatingInput_ts}
                  htmlFor="floatingInput"
                >
                  First Name
                </label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control ${
                    lastNameState.isValid === false ? `is-invalid` : ``
                  }`}
                  id="floatingInput"
                  placeholder="Last Name"
                  value={lastNameState.val}
                  onChange={lastNameChangeHandler}
                />
                <div id="validationServer05Feedback" class="invalid-feedback">
                  Must be atleast 1 letter
                </div>
                <label
                  className={Styles.floatingInput_ts}
                  htmlFor="floatingInput"
                >
                  Last Name
                </label>
              </div>
            </div>
          </div>
          <div className="row g-2 mt-3">
            <div className="col-md">
              <div className="form-floating mb-3 is-invalid}">
                <input
                  type="number"
                  className={`form-control ${
                    phoneNoState.isValid === false ? `is-invalid` : ``
                  }  ${Styles.inputmod}`}
                  id="floatingInput"
                  placeholder="Phone Number"
                  value={phoneNoState.val}
                  onChange={phoneNoChangeHandler}
                />
                <div id="validationServer05Feedback" class="invalid-feedback">
                  Please provide valid number
                </div>
                <label
                  className={Styles.floatingInput_ts}
                  htmlFor="floatingInput"
                >
                  Phone Number
                </label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control ${
                    collegeState.isValid === false ? `is-invalid` : ``
                  }`}
                  id="floatingInput"
                  placeholder="College"
                  value={collegeState.val}
                  onChange={collegeChangeHandler}
                />
                <div id="validationServer05Feedback" class="invalid-feedback">
                  Must be atleast 4 letters
                </div>
                <label
                  className={Styles.floatingInput_ts}
                  htmlFor="floatingInput"
                >
                  College
                </label>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-12">
              <div className="form-floating my-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Invite Key"
                  value={inviteKey}
                  onChange={(e) => setInviteKey(e.target.value)}
                />
                <label
                  className={Styles.floatingInput_ts}
                  htmlFor="floatingInput"
                >
                  Invite Key (if any)
                </label>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-10">
              <div className="form-floating my-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Coupon Code"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <label
                  className={Styles.floatingInput_ts}
                  htmlFor="floatingInput"
                >
                  Coupon Code (if any)
                </label>
              </div>
            </div>
            <div className="col-2 my-auto">
              <button
                type="button"
                className="btn btn-outline-secondary ms-2 px-3"
                onClick={handleCoupon}
              >
                {loading.applyCoupon ? (
                  <BeatLoader size={10} color="#1ac788" />
                ) : (
                  <span>Apply</span>
                )}
              </button>
            </div>
          </div>

          <div className="d-grid gap-2 mt-4 mb-3">
            <button
              className={`btn ${Styles.btnBlockMod} py-2`}
              type="button"
              onClick={handleRayzorpay}
            >
              {loading.paymentProceed ? (
                <BeatLoader size={10} color="#FFFFFF" />
              ) : (
                <span>Proceed To Payment</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
