export const linkedinData = [
  {
    link: "https://www.linkedin.com/posts/pranavmishra19_internship2022-goldmansachs-makingthingspossible-activity-6829261316243427328-5P_v",
    name: "Pranav Mishra",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_PRANAV.jpg",
    testimonial:
      "Before joining AlgoZenith in May, I was thinking not to sit in the internship drive of my college as I was pretty sure that I wouldn’t be able to crack ...",
  },
  {
    link: "https://www.linkedin.com/posts/akshita-jajoo-b17914112_internship-activity-6832969591979868160-Cr7E",
    name: "Akshita Jajoo",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_AKSHITA.jpg",
    testimonial:
      "I would like to thank Vivek Gupta, Rohan Ishwarkar for being such excellent mentors and the whole team of AlgoZenith for the course as it was excellent and  ...",
  },
  {
    link: "https://www.linkedin.com/posts/shivam-shrivastava-ab6404179_internship-microsoft-summerinternship-activity-6831494766975299584-mmfi",
    name: "Shivam Shrivastava",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_SHIVAM.jpg",
    testimonial:
      "Special thanks to Vivek Gupta and Rohan Ishwarkar for being excellent mentors. Vivek's ability to break down complicated concepts into easily understandable ...",
  },
  {
    link: "https://www.linkedin.com/posts/ayush-surana-6671631b1_intern-technology-oracle-activity-6831637967816286208-kvPO",
    name: "Ayush Surana",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_AYUSH.jpg",
    testimonial:
      "Thanks to Vivek Gupta, Shubham Patil, my mentor, Rohan Ishwarkar and the whole team of AlgoZenith for shaping me into a better version of myself.",
  },
  {
    link: "https://www.linkedin.com/posts/rishav-kumar-abbb63143_summerinternship-gratitude-goldmansachs-activity-6833023824485855232-qtde",
    name: "Rishav Kumar",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_RISHAV.jpg",
    testimonial:
      "Thanks to Vivek Gupta and the whole team of AlgoZenith for constantly guiding me and motivating me to perform better.",
  },
  {
    link: "https://www.linkedin.com/posts/ruchi-shukla-139a8b194_intern-softwaredeveloper-technology-activity-6831318755818147840-E0P6",
    name: "Ruchi Shukla",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_RUCHI.jpg",
    testimonial:
      "Thanks to Vivek Gupta, Shubham Patil, my mentor, and the whole team of AlgoZenith for shaping me into a better version of myself.",
  },
  {
    link: "https://www.linkedin.com/posts/ronak-agarwal-b7a04b1ab_internship-futureshaper-honeywell-activity-6831250112468017154-HL_I",
    name: "Ronak Agarwal",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_RONAK.jpg",
    testimonial:
      "Many thanks to Vivek Gupta, my mentor, and the AlgoZenith team. Vivek sir's unique manner of explaining complicated topics with such simplicity and clarifying even ...",
  },
  {
    link: "https://www.linkedin.com/posts/vikash-gope-9816a4194_infosys-infytq-icpc-activity-6830593107281756160-tbfm",
    name: "Vikash Gope",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_VIKASH.jpg",
    testimonial:
      "I would like to thank Vivek Gupta (AlgoZenith community) for shaping my coding skills",
  },
  {
    link: "https://www.linkedin.com/posts/activity-6833795276851970048-bdVz",
    name: "Saamira Yasmin",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_SAAMIRA.jpg",
    testimonial:
      "A big thank you to the AlgoZenith team, especially Vivek Gupta and Rohan Ishwarkar. I was enrolled in the AZ-201 course and it helped me immensely in my journey .....",
  },

  {
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6840702878890500096",
    name: "Naman Jajani",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_NAMAN.jpg",
    testimonial:
      "Thanks to the team of AlgoZenith, especially Vivek Gupta, for making the journey of competitive programming easier and enjoyable for me.",
  },
  {
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6840605967151898624",
    name: "Soumyadeep Nandi",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_SOUMYA.jpg",
    testimonial:
      "I would like to thank Vivek Gupta, Rohan Ishwarkar and the entire team of AlgoZenith for covering all the basics and intermediate concepts in DS and Algo",
  },
  {
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6839861358033432576",
    name: "Shivam Bhosale",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_SHIVAMBS.jpg",
    testimonial:
      "I want to thank AlgoZenith mentors Vivek Gupta and Shubham Patil for guiding me through the preparations.",
  },
  {
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6848882609477484545",
    name: "Yash Agrawal",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_YASHAG.jpg",
    testimonial:
      "I take this opportunity to thank Vivek Gupta and all my mentors in the AlgoZenith team, for providing an exponential growth to my CP journey!",
  },
  {
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6837660424750542848",
    name: "Akash Dhingra",
    photo:
      "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LKD_AKASH.jpg",
    testimonial:
      "The way in which Vivek Sir teaches the concepts helped me to observe the patterns of questions and come up with the most effective solution during the interviews in very little time.",
  },
];







export const allStudents = [
  {
    "name": "Avi Sankritya",
    "testimonial": "The overall course structure is well planned and the mentors are excellent. This course has helped in building my concepts of Competitive Programming. The teaching methodologies and the tricks and technques discussed during the course are really good and have helped me solve a lot of problems on different platforms like Codeforces,SPOJ etc. Almost every topic of Data Structures and Algorithms was covered in a very efficient manner. I would recommend anyone willing to improve his/her programming skills to join this course.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/GS.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_1_AVI.jpg",
    "color": "rgba(100,168,240,0.8)"
  },
  {
    "name": "Pranav Mishra",
    "testimonial": "I joined the AZ-201 to prepare myself for the upcoming internship season, and I was a complete newbie in the world of coding. But soon, with the help of this well-structured course, the exhaustive problem set of each and every topic, and the excellent teachings given by the teachers helped me a lot to vanish the fear of coding and within a very short span of time, I could see myself in a position to easily come-up with the solution of any new question given to me in the interviews. The whole community of AlgoZenith always motivated me to work harder everyday on coding, which eventually helped me to crack Goldman Sachs on Day 1 itself. I will always be thankful to the members of AlgoZenith for building such platform and helping average students like me to easily crack one of their dream companies.I would highly recommend this course to anyone who is just starting his preparation or someone who believes that there is something missing in his preparation. I can guarantee that AlgoZenith will convert you from bronze to gold, and if you are gold, then to diamond.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/GS.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_2_PRANAV.jpg",
    "color": "rgba(100,168,241,0.8)"
  },
  {
    "name": "Vedavittalvihar Terala",
    "testimonial": "This is one of the best structured, fun filled, helpful course in my learning curve. Before, I was quite afraid of programming but the mentors here made it soo overwhelmed and joyful experiences with live sessions of tips and tricks. I am glad I found Algozenith. The guide mode helped me with time management, the contests, and mock tests made us prepared for the real world.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/GS.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_3_VIHAR.jpg",
    "color": "rgba(100,168,242,0.8)"
  },
  {
    "name": "Jayanth Yindukuri",
    "testimonial": "In January, I realized the importance of CP in bagging a decent internship. I was clueless about where to start CP when I came across AlgoZenith. I started my journey with competitive programming with the AZ201 course. From C++ STL to Advanced Dynamic Programming, it has an excellent curriculum that laid the foundations for CP and helped me gain confidence in a short period. AZ was a matchless combo of splendid curriculum and exceptional mentor-ship. That's all you need! Also, it was so on point, and the mock interview was of tremendous help!",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/TOWER.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_17_JAYANTH.jpg",
    "color": "rgba(67,87,138,0.8)"
  },
  {
    "name": "Debajit Chakraborty",
    "testimonial": "Before taking this course I was just a beginner who aimlessly just gave contests, never upsolved(analyse the problems after giving the contest) and was below average. However this course gave it a direction with clearly defined topics, questions, contests at regular intervals and doubt sessions. There were so many things like binary search invariant which I used earlier without knowing why it was correct and the course taught me the importance of self analysis as done by regular contest discussions after contests. This definitely boosted my cp skills by huge amounts which would have otherwise taken huge number of hours, time waste on searching for content, aimlessly doing questions at a very minimal and reasonable cost. It would definitely provide to be a huge role behind the upcoming internships sessions and I am much more confident about my upcoming internships and placements than I was 3 months ago.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/QUADEYE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_18_DEBAJIT.jpg",
    "color": "rgba(50,176,228,0.8)"
  },
  {
    "name": "B Sai Vamshi",
    "testimonial": "At the start of summer, I barely remembered my DSA course concepts and gave my first contest. In the short span of time. The confidence and skills that AlgoZenith gave me was stupendous",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/QUADEYE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_19_SAI.jpg",
    "color": "rgba(50,176,229,0.8)"
  },
  {
    "name": "Rajat Bachhawat",
    "testimonial": "The problemsets are so meticulously curated that each problem has something new to teach you, and nothing is repetitive. Thus, I got amazing targeted practice of DS/Algo questions - which I did not find elsewhere. Not only do the mentors explain topics in the most easy to understand way possible, they also discuss the approach to intriguing problems interactively in live sessions, which helps in understanding concepts concretely. Also, the mentors are always available for doubt clearing and general advice. Without doubt, AlgoZenith's course was instrumental in building my problem solving aptitude, improving my competitive programming skills, and helping me get an internship.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/QUADEYE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_20_RAJAT.jpg",
    "color": "rgba(50,176,230,0.8)"
  },
  {
    "name": "Shrayam Mitra",
    "testimonial": "The course is very well designed and covers all the topics that are frequently asked in coding rounds and interviews and much more. If you enjoy CP then AZ would be your dreamland, it provides you with live lectures, problem collections and educational contests. And I must mention that the coding Bootcamp organised just before the internship month helped me revise all the important tricks/techniques and gave me confidence. Thank you AlgoZenith.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/QUANTBOX.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_21_SHRAYAM.jpg",
    "color": "rgba(217,119,71,0.8)"
  },
  {
    "name": "Arusarka Bose",
    "testimonial": "The course structure is exhaustive and comprehensively covers anything and everything which you can possibly encounter in tests or interviews. The mentors here are proficient in breaking down the most complex of algorithms into an easy-to-understand manner, and are extremely approachable. Though I did not have much background in C++ before joining the course, Algozenith helped me reach a proficiency level in coding which was enough to solve most of the questions that I encountered. The problems given on the platform covered all of the possible variations in Data Structures and Algorithms and I did not need to solve questions from any external platform. I would recommend anyone willing to better his/her programming skills to join this course.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/APT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_22_ARUSARKA.jpg",
    "color": "rgba(23,95,175,0.8)"
  },
  {
    "name": "Bhosale Shivam Pravin",
    "testimonial": "The course structure of AZ-201 is uniquely defined. I found the topics to be very relevant to the questions I was asked in the coding rounds of companies. The mentors were very helpful throughout the course.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/ADOBE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_23_SHIVAM.jpg",
    "color": "rgba(204,33,49,0.8)"
  },
  {
    "name": "Anany Sharma",
    "testimonial": "The question bank and the structure of Az-201 course benefitted me greatly. Vivek sir has a unique way of explaining concepts with multiple approaches, all while keeping it intuitive, which helped me in solving new questions. The doubt platform and the interview bootcamp were also quite helpful. AZ mock tests were quite close to the Real Coding Tests of Companies which helped me to prepare in a similar environment.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/AMAZON.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_24_ANANY.jpg",
    "color": "rgba(37,49,63,0.8)"
  },
  {
    "name": "Madhumita Paul",
    "testimonial": "Programming can be very overwhelming to begin with for the most of us. I am glad I found Algozenith where the right guidance from the mentors, the appropriate study material and weekly contests helped me eliminate the fear. Thanku Algozenith for making it possible in such a short span and assisting me through the placements.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/AMEX.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_25_MADHUMITA.jpg",
    "color": "rgba(6,112,207,0.8)"
  },
  {
    "name": "Rahul Ramesh",
    "testimonial": "I joined the AZ-201 course with no experience in coding and high pressure of performing well in selection tests in the imminent campus internship drive. I took a massive leap with algozenith to overcome my inner fear of coding. The course is well structured with an exhaustive set of topics and proper intervals to assimilate the learnings, which we got to test through the weekly contests and the plethora of practice problems to hone our implementation skills. The personal attention given by mentors and the active doubt-solving forum is unparalleled. The unique tricks and techniques taught are invaluable assets we have inherited from them for life.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/AMEX.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_26_RAHUL.jpg",
    "color": "rgba(6,112,207,0.8)"
  },
  {
    "name": "Adarsh Prakash Vemali",
    "testimonial": "The course helped me develop problem solving skills which helped me in my quest for Data Science. I was equipped to develop better and more efficient ML algorithms in my many projects and internships. The AZ bootcamp offered benefits like no other platform to prepare for Quantitative Aptitude, Puzzles and Probability based questions. Algozenith offered a competitive but positive environment which helped me in constant and sustained push to strive to improve and hone my problem solving skills.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/AMEX.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_27_ADARSH.jpg",
    "color": "rgba(6,112,207,0.8)"
  },
  {
    "name": "Souradeep Bera",
    "testimonial": "The course is extremely well structured and covers all the topics which would be required by you to crack the company coding tests/interviews and also gives an extremely good foundation for starting CP incase that is your goal. The best part about this course is it gives a comprehensive list of concepts and questions. It makes use of your time in the most efficient manner. The mentors also respond very quickly to any doubts which you might have and gets you in touch with like-minded people to prepare for your goal.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/SPRINKLR.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_28_SOURADEEP.jpg",
    "color": "rgba(102,109,107,0.8)"
  },
  {
    "name": "Mayukh Raychaudhuri",
    "testimonial": "Thanks to Vivek Sir and all the Algozenith mentors for clarifying each and every critical concepts of Algo-DS so easily. Besides, the mock interview and the newly made mock test platform helped me a lot to boost my confidence.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/SPRINKLR.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_29_MAYUKH.jpg",
    "color": "rgba(102,109,107,0.8)"
  },
  {
    "name": "Shruti Shreyasi",
    "testimonial": "The course structure is well planned and structured. The mentors are very helpful. This course helped me in interviews, coding tests as well as competitive programming. The live sessions, recorded videos and mock tests are really helpful. The interview boot-camp was a great platform to revise important concepts before the tests. All the major algorithms and programming paradigms are discussed in detail with many practice problems in each section.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/SPRINKLR.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_30_SHRUTI.jpg",
    "color": "rgba(102,109,107,0.8)"
  },
  {
    "name": "Nikunj Kumar Madhogaria",
    "testimonial": "When in an interview you hear a question which you had already solved and you remember it properly, there is no matching to the feeling at that point. This happened many times with me not only in interviews but also in coding tests, some were a bit twisted but this course builds a skill to reduce new problems to known problems. The experience of mentors here is something on another level, they make us practice all the standard and common problems. The course is properly structured to make us understand each topic properly, practice a given set of questions and take a contest on that topic, then move on once we feel confident in it. I would still be struggling if not for AlgoZenith.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/SPRINKLR.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_31_NIKUNJ.jpg",
    "color": "rgba(102,109,107,0.8)"
  },
  {
    "name": "Nuruddin Jiruwala",
    "testimonial": "The course was extremely well structured, and covered all the topics possible. Almost all the Algo DS questions I faced in all my interviews, I had either solved them or something similar on the platform. Everything is explained in the clearest way possible. Vivek is an excellent teacher and under his guidance, my problem solving skills improved significantly and after that I could solve problems on SPOJ, Leetcode etc. This course enabled me to bag an internship at Sprinklr, along with helping me qualify for other companies as well. I would highly recommend this course to anyone trying to increase their Algo DS/ problem solving skills.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/SPRINKLR.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_32_NURUDDIN.jpg",
    "color": "rgba(102,109,107,0.8)"
  },
  {
    "name": "Ojasv Kamal",
    "testimonial": "Course structure and content was simply great. Saved time in a lot of areas.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/SPRINKLR.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_33_OJASV.jpg",
    "color": "rgba(102,109,107,0.8)"
  },
  {
    "name": "Sahil Jindal",
    "testimonial": "The course is well structured to make you prepared for the coding tests as well as interviews of all the big tech companies. The personal attention given by mentors and doubt solving is really helpful. The live classes as well as the recorded lectures helped my thinking process a lot. The bi-weekly contests helped me to improve my implementation skills. You\u2019ll enjoy CP while doing this course which is very important to improve your coding skills. The course helped me immensely to bag an internship at Microsoft and qualify for various other companies!",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_7_SAHIL.jpg",
    "color": "rgba(128,204,40,0.8)"
  },
  {
    "name": "Naman Paharia",
    "testimonial": "I joined algo zenith to boost my preparation for incoming interns and to learn cp in an organised and systematic manner. This course covered all the basics and intermediate concepts in ds and algo. Mentors were very helpful and motivating, and their long experience in cp helped in implementing long algorithms very concisely and efficiently. Part which I liked the most was the regular practice contest and the discussion session after each contest. I would recommend aspiring competitive programmers to not miss the opportunity of being mentored by India's best programmers.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_8_NAMAN.jpg",
    "color": "rgba(128,204,41,0.8)"
  },
  {
    "name": "Shivam Saxena",
    "testimonial": "The course structure was really good and adequate time was devoted to all topics. The weekly contests and subsequent doubt-clearing sessions helped me greatly to improve my problem-solving skills. The mentors were very supportive and would always be available to clear any doubts I had.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_9_SHIVAM.jpg",
    "color": "rgba(128,204,48,0.8)"
  },
  {
    "name": "Sri Harika Reddy",
    "testimonial": "The course is well structured and the mentors are really helpful. Anyone with a decent knowledge of algorithms, would also find a lot to learn from the course. AZ-201 has definitely played a key role in helping me improve my implementation skills, the problem sets are really challenging and helped me push myself out of comfort zone to learn more .In short, I would say AZ-201 has been the best investment of my preparation time.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_10_HARIKA.jpg",
    "color": "rgba(128,204,49,0.8)"
  },
  {
    "name": "Shivam Shrivastava",
    "testimonial": "Perfect mentors, amazing course structure and a recipe of thoughtfully chosen problems - Algozenith has it all! I could not have asked for a better course for myself. Firstly, I would like to thank Vivek. His teaching approach is outstanding as he can break very complicated concepts into smaller pieces and then joins them together beautifully. Secondly, Rohan always kept improving the platform and kept introducing extremely helpful features. Undoubtedly, it is the best training available and I am very happy that I chose to be a part of the Algozenith community!",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_11_SHIVAM.jpg",
    "color": "rgba(128,204,50,0.8)"
  },
  {
    "name": "Radhika Gupta",
    "testimonial": "The Algozenith courses are very well structured and organised, with relevant set of questions, regular contests and efficient doubt clearing forum. This course strengthens the understanding of data structure and algorithms and improves implementation skills. The lives sessions are very much engaging and motivating. Mentors are amazing and easy to reach out. One of the best platform to not only prepare for internships and placements, but also for higher level of competitive programming.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_12_RADHIKA.jpg",
    "color": "rgba(128,204,51,0.8)"
  },
  {
    "name": "Soumick Pyne",
    "testimonial": "After an arduous and unsuccessful attempt at learning competitive programming from random blogs and problem sets on the internet, I enrolled in Algozenith's AZ-201. Live lectures were a perfect mix of conceptual teaching, discussion, and fun. The platform had numerous features to help me practice and track my progress according to my specific target. The course was enjoyable through and through because of prompt doubt resolution by the mentors, weekly coding contests, and contest discussion sessions. My notes from AZ-201 are now a treasured possession, and I have Algozenith to thank for it.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_13_SOUMICK.jpg",
    "color": "rgba(128,204,52,0.8)"
  },
  {
    "name": "Abhishek Suresh Pachorkar",
    "testimonial": "After many random unsuccessful attempts of learning CP from CF, CC, leetcode, I found Algozenith-201. The course is very well structured and covers all the topics. Everything is explained in the clearest way possible. Live lecture of mentors is the perfect mix of teaching discussion, discussion, and practices. Vivek is an excellent teacher and under his guidance, my problem-solving skills improved significantly. This course helped me bag an internship at Microsoft along with getting shortlisted for Rubrik, Sprinklr, and Alphagrep. I would highly recommend this course to anyone who is trying to bag a good internship or placement.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_14_ABHISHEK.jpg",
    "color": "rgba(128,204,53,0.8)"
  },
  {
    "name": "Itish Agarwal",
    "testimonial": "This course really helped me learn new things and whats better than learning from other's mistakes. I would like to thank all mentors and teachers for taking out time and teaching us valuable stuff. Its fair enough to say that the course made me gain confidence in cp and I have no doubt in saying that taking this course was the turning point in my journey of learning cp.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_15_ITISH.jpg",
    "color": "rgba(128,204,54,0.8)"
  },
  {
    "name": "Akshita Jajoo",
    "testimonial": "The course is well-structured overall, and the mentors are outstanding. The live classes as well as the recorded lectures helped my thinking process a lot. I was a beginner when it comes to competitive programming, and it greatly helped me in learning all of the concepts, including the coding style. The mentors were always encouraging and helpful, and they made sure that all of our questions were answered.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MICROSOFT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_16_AKSHITA.jpg",
    "color": "rgba(128,204,55,0.8)"
  },
  {
    "name": "Shubham Maddhashiya",
    "testimonial": "Before joining Algozenith, I was preparing for coding interviews myself and I always felt the need of structured preparation plan. So, I joined AZ 203. Mentors at Algozenith are always ready to help and they always make sure that forum posts are resolved. Their teaching style is great and they make complicated algorithms easy-peasy. I got to learn new techniques and the weekly contests boosted my confidence. Overall, The course is very structured and they have the best problem sets to test understanding of algorithms and implementation skills.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/BIDGELY.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_34_SHUBHAM.jpg",
    "color": "rgba(106,179,224,0.8)"
  },
  {
    "name": "Shubhesh Anand",
    "testimonial": "I really enjoyed this course as it covered all the basic and intermediate concepts of algo and ds in a very elegant manner and helped me develop a good intuition of the concepts taught. The mentors were very encouraging and helpful at all times and made sure all our doubts were cleared. The regular educational contests held to test our concepts were certainly one of the highlights of this course. This course was immensely helpful and the time and efforts dedicated to complete this course are worth it. Thank you.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/CREDIT_SUISSE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_35_SHUBHESH.jpg",
    "color": "rgba(8,60,102,0.8)"
  },
  {
    "name": "Aviraj Singh Bevli",
    "testimonial": "A very well structure course! All the concepts relevant to the internships are covered in detail and ample practise material is provided. Vivek Gupta, our batch's mentor, has an amazing talent for explaining complex concepts in a simplified way",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/CREDIT_SUISSE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_36_AVIRAJ.jpg",
    "color": "rgba(8,60,102,0.8)"
  },
  {
    "name": "Soumyajit Chakraborty",
    "testimonial": "Vivek is no doubt a great guide. The course is really really complete in itself for our Internship drive at least. I am really thankful to AlgoZenith because of my improvement from an absolute nob in DSA to a good preparation in Intern drive.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/CREDIT_SUISSE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_37_SOUMYAJIT.jpg",
    "color": "rgba(8,60,102,0.8)"
  },
  {
    "name": "Vinit Raj",
    "testimonial": "Course structure is very beginner friendly, ramps up really well with time. It felt very one on one since almost all doubts were cleared in time and even code bugs which are painful and time consuming to find. i learned a lot about the standard practices that helped me avoid making bugs and writing code that runs on first try. The mentors not only knew stuff but more importantly knew how to deliver it others and that is a very rare combination of skillsets. Overall it was a great learning experience.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/DESHAW.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_38_VINIT.jpg",
    "color": "rgba(201,235,227,0.8)"
  },
  {
    "name": "Aditya Amrit",
    "testimonial": "A well designed, swift course that makes your transition from being a beginner at coding to excelling at it quite easy. The practice tests will be a boon and simulate the actual coding rounds very well. Lastly and most importantly, the mentors; from helping you get the algorithms right, to developing a coding style which is always bug free, they're always just a query away. On top of it, they help one think about how to approach a problem, which acts like a charm during actual coding tests.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/FM.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_39_ADITYA.jpg",
    "color": "rgba(55,193,115,0.8)"
  },
  {
    "name": "Abhishek S. Purohit",
    "testimonial": "The course is designed in a very systematic manner, and the best part is that they teach every Algo and DS concept from scratch and take it to the advanced level. Mentors are very helpful. Live sessions helped me a lot in grasping new concepts quickly and efficiently, and they helped me maintain my momentum throughout the course. The platform has a lot of quality questions which helps in developing the ideas and making them strong. Contests are organized regularly, along with dedicated contest discussion sessions. The doubt forum is also excellent.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_40_ABHISHEK.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "Sath Prakash Chimmani",
    "testimonial": "Everything about AlgoZenith is incredible, spending elaborate time on even small topics gave the utmost clarity helped me a lot in understanding the concept and to solve problems simultaneously. Vivek and his team had done an excellent job in organising the sessions and to maintain the course structure. Mock Interviews and mock tests helped me a lot to realise because they are almost so realistic to the actual coding rounds and interviews.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_41_SATH.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "S Teyjus Darshan",
    "testimonial": "The course is very well structured that it takes you from C++ STL to advanced topics like graphs, DP etc with ease. The online live sessions are so good and the mentor, Vivek Gupta really knows his stuff. Not just that, he also knows how to explain the concepts in the easiest ways possible. The coding styles that was taught in the course have helped me do a task in the fastest way possible in many coding tests. You can learn the algorithms from anywhere, but the little tricks and tips that you get during the live sessions is what makes this course stand out. The problems and forum which are available on the platform were really helpful to clear my doubts. In my personal experience it has not taken more than a day to get reply in the forum. I have encountered many people who are skeptical of spending 10K - 15K on an online course. But believe me, it is worth more than what you pay.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_42_TEYJUS.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "Nithish Kannen",
    "testimonial": "The best part about the training is that the mentors are so experienced in CP that they point out potential mistakes / guidelines while covering a topic itself. Also, they have a very good forum for clearing doubts. The problems are handpicked to teach you maximum number of ideas within a few number of questions itself, and the list of ideas covered are fairly exhaustive. The course structure is extremely good and covers almost all of the frequently asked ideas in interviews. Overall it's a great program and is worth every penny. A big thank you to the mentors!",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_43_NITHISH.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "Aishwarya Jitin Soni",
    "testimonial": "The Coursework AZ-201 helped me conquer my fear associated with term \"Competitive Programming\". From the basics of STL to application of dynamic programming in graphs, AlgoZenith paved a well structured and immensely helpful path for me to achieve a level which rather seemed hypothetical. With all the basic knowledge and implementation skills, getting shortlisted for companies interview became a piece of cake. The Course not only focuses on the improvement of Coding skills but also an overall development of an individual for cracking any interview test. The basics of Oops and important puzzle problems gives us a chance to crack the tests for Quant, SWE and many more profiles.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_44_AISHWARYA.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "Ruchi Shukla",
    "testimonial": "Every module of the course is well-structured and designed to help an individual develop coding instinct within oneself. Mentors here have a knack of simplifying complex concepts to digestible form. The whole course focused on application based programming rather than just concepts. Practice and contest problems aimed to cover all sorts of variations and guaranteed deeper understanding of the concept with every successful AC. Live Sessions, contests, mock interview all rolled into one to shape me in a better version of myself.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_45_RUCHI.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "Ronak Agarwal",
    "testimonial": "Vivek sir and the AlgoZenith team not only explain ideas but also administer mock examinations and interviews. AlgoZenith offers several options for increasing a Candidate's self-assurance. I was able to crack Honeywell because of the doubt platform, practice examinations, and interviews. AlgoZenith is a one-stop-shop for all of your preparation needs.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_46_RONAK.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "Aniket Raj",
    "testimonial": "The course was fun ! All the topics and questions relevant to coding tests were covered.The leaderboard system was good as it motivated me to solve more questions to remain in the top 10 XD. The doubt solving page was good and I used to get reply within 24 hrs. Bootcamp was also great as it contained the frequent asked questions.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_47_ANIKET.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "Kamalesh Garnayak",
    "testimonial": "The course structure was excellent. I started doing Cp a month and half before internship and I believe if it was not for algozenith , I would not have been able to grow in this small period of time. Our mentor Vivek Gupta was brilliant in explaining concepts and small implementation tricks which made learning easy . It helped to retain the ideas better. Questions were of a high level with a mix of familiarity and unknown which made solving them a joyful experience. It also covered all the standard questions with some advance ideas which made that any question in the internship tests seem familiar.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/HONEYWELL.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_48_KAMLESH.jpg",
    "color": "rgba(238,27,46,0.8)"
  },
  {
    "name": "Yashica Patodia",
    "testimonial": "My experience with AlgoZenith has helped me to clear all my concepts in DSA as well gain a new perspective while solving cp questions .The course structure which includes 500+ questions, video lectures, live sessions, AlgoZenith weekly contests, discussions after every cf or any important contest and doubt clearing portal has been of tremendous help . The mentors at AlgoZenith were really helpful and made sure that all our doubts were resolved during live lectures,discussion forums or otherwise.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/GOOGLE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_4_YASHICA.jpg",
    "color": "rgba(251,188,6,0.8)"
  },
  {
    "name": "Balakrushna Dash",
    "testimonial": "The course AZ- 202 was very well structured. It covered all the essential aspects of Competitive Programming from the basics. The challenging environment here kept pushing me beyond my comfort zone. Moreover, all the mentors' unique teaching style and guidance to tackle the difficulties made the course special.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/GOOGLE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_5_BALKRUSHNA.jpg",
    "color": "rgba(251,188,7,0.8)"
  },
  {
    "name": "Nandini Jalan",
    "testimonial": "AZ has helped me a lot in my Competitive Programming journey. The course content is very well structured and covers almost every topic one might need for a coding test or an interview. The question set on the platform is just amazing! The mentors have a really good teaching methodology and are very helpful and quick in catering to the queries of the students. The small implementation details and tricks shared by them is very useful. The best thing about AZ in my opinion is that it helps you with your preparation in every possible way, be it conducting mock interviews for students, hosting frequent contests, doubt solving forums, it is a compilation of everything.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/GOOGLE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_6_NANDINI.jpg",
    "color": "rgba(251,188,8,0.8)"
  },
  {
    "name": "Sanket Kumar Singh",
    "testimonial": "The coursework was well suited for me as I had some experience with the fundamentals. We were provided sufficient no. of unique and instructive problems. This method of guided practice is much more efficient than foraging through hundreds of random problems in the search of the few good ones. The mentors taught us some truly unique tricks and thinking approaches which I don't think are mentioned elsewhere. They are really good at explaining the tough topics, which require days of effort, in a couple of hours. The excellently curated contests have also been very helpful in covering all the gaps in my knowledge of fundamentals and in covering the more interview-oriented problems. The mentors are among the best competitive programmers in India and no competitive programmer should miss out on the opportunity to learn from them.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/INTUIT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_49_SANKET.jpg",
    "color": "rgba(55,95,191,0.8)"
  },
  {
    "name": "Avijit Mandal",
    "testimonial": "The course structure is really awesome and covers basic topics like tips and tricks in STL to advanced topics like DP, matrix exponentiation. Previously I used to solve only Adhoc problems from different sites but AlgoZenith gave me wide exposure to all kinds of topics used in CP that are heavily asked in the coding tests as well as interviews. Also, mentors provide robust ways to write some particular algorithm, that we can use everywhere. You should definitely consider the course if you want to learn DSA in a structured way.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/INTUIT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_50_AVIJIT.jpg",
    "color": "rgba(55,95,191,0.8)"
  },
  {
    "name": "Kushagra Khullar",
    "testimonial": "What did I like about the course? Actually Everything There is a huge variety of Problems on the platform and in every problem, you learn a new concept and the live sessions are the best and after every topic, you will have contests which give you confidence about that topic also the mentors are very helpful. When I started the course I was a newbie(960) at codeforces but within 1.5 months I became a specialist(1500+). Taking this course proved to be the best decision of my life.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/INTUIT.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_51_KUSHAGRA.jpg",
    "color": "rgba(55,95,191,0.8)"
  },
  {
    "name": "Arun Sammit Pandey",
    "testimonial": "The experience with the course was really great. Before joining the course I was very weak in coding. I had literally no knowledge about C++ libraries like STL and basic concepts and algorithms of competitive programming like Binary Search, Prefix sum, Number Theory, DP tricks. I also had no prior experience in Competitive Coding. I was always in doubt regarding the right question/platform to practice. Since I was on a time crunch(as in my college, internship season was coming up) I joined CP201 batch 1 after seeing a post in the CP workshop group of my college. Joining the course turned out to be one of the best decisions I have made in my entire life. The guidance from mentors(CV preparation tips, interview preparation strategies, etc), the training set(coding questions) given for us to practice, the doubt support, the timed practice contests (AlgoZenith Contest Test Series), the peer learning, etc were awesome. If you are someone who wants to crack the coding rounds and interviews of Software companies for placements or internships and you don't have much time left or want a proper roadmap to accelerate your learning I will definitely recommend you to enroll for AlgoZenith courses.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MASTERCARD.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_52_ARUN.jpg",
    "color": "rgba(247,158,27,0.8)"
  },
  {
    "name": "Ashish Gupta",
    "testimonial": "I had an amazing experience at Algozenith. The course is structured so accurately that if one follows it thoroughly ,it would be just enough. The mentors are very helpful and extremely friendly and they guide you in all possible way.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MASTERCARD.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_53_ASHISH.jpg",
    "color": "rgba(247,158,27,0.8)"
  },
  {
    "name": "Guruvansh Singh Bhatia",
    "testimonial": "The concept are taught in a very systematic way. The problemset provided stands at par with Questions asked. Regular live sessions, instant doubt clearing forum and practice contests helped a lot.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MASTERCARD.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_54_GURUVANSH.jpg",
    "color": "rgba(247,158,27,0.8)"
  },
  {
    "name": "Mintu Agarwal",
    "testimonial": "I joined AZ-202 course at AlgoZenith. Seeing my initial estimates about topics in Competitive Programming, I must say that I am really satisfied. Not only Algorithms and Data Structures, but the mentors also focus on important techniques and skills like debugging, approach to problems, which a lot of time gets ignored in Competitive programming. Regular contests by AlgoZenith and after-contest discussions sessions helped me learn a great deal. Course started with some basic but important algorithms and then gradually the bar was raised so that we have hold on every topic. The mentors actively solved my doubts in quick time whenever I got stuck on some problem from my practice sheet also suggesting different solutions to it which improved my problem-solving skills.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MORGAN.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_55_MINTU.jpg",
    "color": "rgba(55,55,55,0.8)"
  },
  {
    "name": "Suryansh Kumar",
    "testimonial": "The training provided by Algozenith is quite comprehensive. It covers an extensive set of problems ranging from hard CP problems to tricky questions commonly asked in interviews. The concepts required to solve these problems are taught by mentors, who are experts in their field. It does not matter if you're a CP beginner or an advanced programmer, your skill will definitely improve at Algozenith.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/NOMURA.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_56_SURYANSH.jpg",
    "color": "rgba(218,37,68,0.8)"
  },
  {
    "name": "Vishal Raj",
    "testimonial": "AZ-301 course helped me learn many new topics that I was unfamiliar with and individually would have taken much more time to master. Each week consisted of 2 sessions. Before sessions, reading materials are given and during them, the problems covering 'standard' ideas related to the topic being discussed. After the sessions problems are added to 'Practice Sheet', which is regularly checked by mentors to track progress. Each of these problems is a unique learning experience covering some new ideas and building upon the previous ones. Mentors are super helpful, each of them has their unique way of teaching, I liked it all! Weekly contests were organized which helped me brush over topics common in coding rounds and interviews. Post-contest discussion sessions (for CF contests, Google Kickstart, etc) helped me gain crucial insights on problem-solving strategies which I often find missing in the editorials. I would say mentors know what is right for you at the moment and they structure the course accordingly, many times 'Special-sessions' were organized to cover weak areas. I would say a serious dedication from one's side is also required to make this a success. Super lucky to be a part of it!",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/NUTANIX.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_57_VISHAL.jpg",
    "color": "rgba(194,218,117,0.8)"
  },
  {
    "name": "Pramita Kastha",
    "testimonial": "Vivek Sir explains concepts in a unique way which no one else could. Doubt assistance facility is good. Problems in the platform are carefully handpicked to cover maximum number of concepts. Really great course to upskill oneself in DSA in minimum possible time.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/ORACLE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_58_PRAMITA.jpg",
    "color": "rgba(91,91,91,0.8)"
  },
  {
    "name": "Varun Madhavan",
    "testimonial": "AZ 201 is an excellent course that covers all the most important topics in Competitive Programming commonly asked in the selection processes of software companies. The mentors were very helpful and supportive and helped me a lot throughout the process! A lot of sessions were also conducted for doubts that we may have about HR rounds, important concepts/intricacies of different profiles etc. Thank you so much AZ Team!",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/P&G.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_59_VARUN.jpg",
    "color": "rgba(23,95,171,0.8)"
  },
  {
    "name": "Amisha Agarwal",
    "testimonial": "I was really nervous about coding rounds before I enrolled in Algozenith. AZ-202 elevated my confidence completely. The course structure has been designed very meticulously. I really learned a lot through the interactive live sessions which prompted us to think and answer. With Algozenith coding became fun as basic concepts were made very clear. The dedicated team of Algozenith conducted regular coding contests and discussion/doubt sessions. All you need is consistency and willingness to learn and leave the rest to the team. Algozenith is an investment which gave manifold returns.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/SALESFORCE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_60_AMISHA.jpg",
    "color": "rgba(0,161,224,0.8)"
  },
  {
    "name": "Arundhuti Naskar",
    "testimonial": "Algozenith is the ultimate place for internship and placement preparation. I had joined the AZ-201 batch with absolutely no prior knowledge in ds-algo or competitive coding. The course content is organized into appropriate segments topic-wise with frequent interactive sessions, live contests with discussions, and a very efficient doubt clearing platform. The mentors taught various implementation tricks, debugging techniques, and easy ways to tackle complex algorithms of Graphs, DP, Trees, and Binary Search. The mock interviews and Bootcamp were pivotal for last-minute preparation. The training boosted my CP skills from a beginner level, which finally helped me bag an intern offer.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/SALESFORCE.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_61_ARUNDHUTI.jpg",
    "color": "rgba(0,161,224,0.8)"
  },
  {
    "name": "Rahul Mohata",
    "testimonial": "My experience with AlgoZenith was wonderful. The course strengthened my concepts and helped me in my interviews. A really well structured course for improving DSA skills. The interview bootcamp helped me in my placement season and I want to thank the mentors and the team behind it. I would definitely recommend AlgoZenith.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/WELLS_FARGO.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_62_RAHUL.jpg",
    "color": "rgba(218,47,56,0.8)"
  },
  {
    "name": "Snigdho Banerjee",
    "testimonial": "I was absolutely unaware of the different algorithms and related paradigms of the same before the starting of the course.But,after that this course highly upskilled me.It added multiple dimensions to my thought process.I thank the AZ team for this.The study materials were more than sufficient for the interviews as well as the coding tests.The bootcamp was an ideal one to be followed.Several sessions of various aspects of interviews were also held.All the additional materials other than Algo DS were covered.Mock tests and mock interviews further added decorated my performance as I was able to evaluate myself based on the feedbacks of the mentors,who are always keen to help and guide us anytime.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/AMAZON.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_63_SNIGDHO.jpg",
    "color": "rgba(37,49,63,0.8)"
  },
  {
    "name": "Sahil Shailesh Pedamkar",
    "testimonial": "Algozenith AZ-201 Course is a great course for Internship Preparation. I had joined the course with very little knowledge about Data Structures and Algorithms. It was a joyful learning experience and the course really helps you in sharpening your skills. The Course is very well structured and the mentors will always be there to help you.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/AMAZON.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_64_SAHIL.jpg",
    "color": "rgba(37,49,63,0.8)"
  },
  {
    "name": "Puspaul Halder",
    "testimonial": "Starting from awesome template of binary search to forms of dynamic programming ,the mentors at algozenith make everything as simple as learning to write \"Hello World\" problems.They provide with doubt clearing sessions as well as forums where we can communicate with them anytime.Algozenith helped me to get the confidence to solve difficult problems in time constrained coding tests and interviews.Thanks to the entire team at Algozenith.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/CISCO.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_65_PUSPAUL.jpg",
    "color": "rgba(3,166,222,0.8)"
  },
  {
    "name": "Saamira Yasmin",
    "testimonial": "I was enrolled in the AZ-201 course of AlgoZenith and it was my best decision. The course is well designed and provides all that one needs to develop competitive programming skills. The best part is that I could get all the necessary resources and material at one place itself. The live sessions, weekly contests and its discussions, and mock tests and interviews helped me greatly in taking a big step towards my goal. The instructors were always available to clear my doubts and they teach with great passion and dedication. I would definitely recommend taking AlgoZenith as it provides the right training for competitive programming.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/MASTERCARD.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_66_SAAMIRA.jpg",
    "color": "rgba(247,158,27,0.8)"
  },
  {
    "name": "Aashish Kumar",
    "testimonial": "I joined AlgoZenith's AZ-201. The course is well structured and exhaustively covers everything pertaining to data structures and algorithms as far as the coding tests and interviews are concerned. The live sessions helped me in understanding the concepts and application ideas of various algorithms in a comprehensive manner. The practice problems available on the platform are really good. Moreover, the doubt clearing forum assisted throughout the training.",
    "company_logo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/LOGOS/AMAZON.png",
    "photo": "https://algozenith.s3.ap-south-1.amazonaws.com/testimonials/TEST_67_AASHISH.jpg",
    "color": "rgba(37,49,63,0.8)"
  }
]