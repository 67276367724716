import React, { useContext } from "react";
import Styles from "../Faqs.module.css";
import { FaqContext } from "../../../context/FaqContext";

const FaqsAccordion = () => {
  const { faqData, faqDataHandle } = useContext(FaqContext);
  console.log(faqData);
  let question = faqData.filter((data) => data.id === faqDataHandle);
  console.log(question[0].qa);

  return (
    <>
      <p className={Styles.faqhead}>{question[0]?.head}</p>

      <hr className={Styles.hr} />

      <div className="accordionpt-1" id="accordionExample">
        {/* <div className={`accordion-item ${Styles.accordion_item}`}>
          <h2 className="accordion-header" id="headingOne">
            <button
              className={`accordion-button py-4 collapsed ${Styles.accordion_button}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Which Course Should I take?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className={`accordion-body ${Styles.accordion_body}`}>
              This is the first item's accordion body. It is shown by default,
              until the collapse plugin adds the appropriate classes that we use
              to style each element. These classes control the overall
              appearance, as well as the showing and hiding via CSS transitions.
              You can modify any of this with custom CSS or overriding our
              default variables. It's also worth noting that just about any HTML
              can go within the though the transition does limit overflow.
            </div>
          </div>
        </div> */}

        {question[0].qa?.map((data, index) => (
          <div
            key={index}
            className={`accordion-item ${Styles.accordion_item} mt-4`}
          >
            <h2 className="accordion-header" id="headingTwo">
              <button
                className={`accordion-button collapsed py-4 ${Styles.accordion_button}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="false"
                // aria-controls="collapseTwo"
              >
                {data.question}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">{data.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FaqsAccordion;
