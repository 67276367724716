export class Urls {
  static Main = class {};

  static Learning = class {
    static BaseUrl() {
      return process.env.REACT_APP_LEARNING_URL;
    }

    static LoginUrl() {
      return `${this.BaseUrl()}/login`;
    }

    static RegisterUrl() {
      return `${this.BaseUrl()}/register`;
    }

    static DashboardUrl() {
      return `${this.BaseUrl()}/dashboard`;
    }
  };

  static Practice = class {
    static BaseUrl() {
      return process.env.REACT_APP_PRACTICE_URL;
    }
  };

  static Social = class {
    static DiscordUrl() {
      return `https://discord.gg/Y74V6RY3Qh`;
    }

    static MediumUrl() {
      return `https://algozenith.medium.com`;
    }

    static FacebookUrl() {
      return `https://www.facebook.com/algozenith/`;
    }

    static YoutubeUrl() {
      return `https://www.youtube.com/channel/UCWDMgLMlebz-y8ZPVhsEsow/featured`;
    }

    static InstagramUrl() {
      return `https://www.instagram.com/algozenith`;
    }

    static LinkedInUrl() {
      return `https://www.linkedin.com/company/algozenith`;
    }
  };

  static Intructors = class {
    static VivekLinkedinUrl() {
      return `https://www.linkedin.com/in/vivekgiitkgp/`;
    }

    static VivekYoutubeUrl() {
      return `https://www.youtube.com/@vivekgupta3484`;
    }

    static NitishLinkedinUrl() {
      return `https://www.linkedin.com/in/nitish-rai-055387123/`;
    }

    static ShubhamLinkedinUrl() {
      return `https://www.linkedin.com/in/shubhampatil11698/`;
    }

    static RohanLinkedinUrl() {
      return `https://www.linkedin.com/in/rohanishwarkar/`;
    }
  };

  static PremiumPage = class {
    static DemoVideoURL() {
      return `${process.env.REACT_APP_LEARNING_URL}/user/course/AlgoZenith-Demo-Course-15/overview `;
    }

    static DPWorkshopURL() {
      return `${process.env.REACT_APP_MAIN_URL}/dpworkshop`;
    }
  };

  static Legal = class {
    static PrivacyPolicyUrl() {
      return `https://drive.google.com/file/d/1LhoiPpvK48L5Uu2XQ8WnKUe5glS0ii6f/view?fbclid=IwAR00yn83wmuCz76y4QikYRVlj3eyJnYS-MmauYbfzVW-fRl8MVwI-vr0k0A`;
    }

    static TermsAndConditionsUrl() {
      return `https://drive.google.com/file/d/1YQlfhlZuD8rPIcm9OuZXvMroAICgjqHz/view?fbclid=IwAR0F2zP4mBlc41G1MYMGjIOxayGxO09GnwpdZA23hBUnyxi9rU84KFiJw70`;
    }

    static PriceAndRefundPolicyUrl() {
      return `https://drive.google.com/file/d/1YQlfhlZuD8rPIcm9OuZXvMroAICgjqHz/view?fbclid=IwAR0F2zP4mBlc41G1MYMGjIOxayGxO09GnwpdZA23hBUnyxi9rU84KFiJw70`;
    }
  };
}
