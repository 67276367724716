import React from "react";
import { Link } from "react-router-dom";
import Styles from "./ErrorPages.module.css";

const InternalServerError = () => {
  return (
    <>
      <div className="container">
        <div className="row py-5">
          <div className="col-12 text-center">
            <img
              src="/img/Error-Page/500.svg"
              className="img-fluid"
              width={370}
              alt=""
            />
            <p className={`mt-3  ${Styles.invalid_page}`}>
              Internal Server Error
            </p>
            <p className={Styles.subtext}>
              We're experencing an internal server problem <br />
              Please try after sometime
            </p>
            <Link
              to={"/home"}
              className={`btn mt-2 ${Styles.btnInvalidCourse}`}
            >
              Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalServerError;
